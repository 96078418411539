<template>
  <!-- 导航栏，根据当前主题动态添加暗黑主题类 -->
  <div class="navigation" :class="{ 'dark-theme': currentTheme === 'dark' }">
    
    <!-- 循环显示分类及其对应的链接 -->
    <div
      v-for="category in paginatedCategories"
      :key="category.name"
      class="category"
      :data-category="category.name"
    >
      <!-- 分类标题，点击可展开/收起分类 -->
      <h2 @click="toggleCategory(category.name)" class="category-title">
        <!-- 动态显示分类图标 -->
        <el-icon style="display: inline-flex; margin-right: 4px;">
          <component :is="category.icon" />
        </el-icon>
        {{ category.name }}
        <!-- 根据分类状态显示展开/收起图标 -->
        <i :class="[
          isCollapsed(category.name) ? 'el-icon-arrow-down' : 'el-icon-arrow-up',
          'toggle-icon'
        ]"></i>
      </h2>

      <!-- 当分类未收起时显示链接列表 -->
      <div class="links-container">
        <ul>
          <li v-for="link in category.links" :key="link.name">
            <!-- 链接按钮，带有工具提示显示描述 -->
            <el-tooltip class="item" effect="dark" :content="link.description" placement="top">
              <a :href="link.url" target="_blank"> 
                <el-button :type="currentTheme === 'dark' ? 'primary' : 'plain'">
                  <!-- 显示网站favicon -->
                  <img 
                    v-if="getFaviconUrl(link.url)"
                    :src="getFaviconUrl(link.url)"
                    :alt="link.name"
                    class="favicon"
                    @error="handleImageError($event, link.url)"
                  />
                  {{ link.name }}
                </el-button>
              </a>
            </el-tooltip>
          </li>
        </ul>
      </div>
    </div>

    <!-- 分页器 -->
    <div class="pagination-container">
      <el-pagination
        v-model:current-page="currentPage"
        :page-size="pageSize"
        :total="filteredCategories.length"
        layout="prev, pager, next"
        @current-change="handlePageChange"
        background
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationLinks',
  // 组件属性定义
  props: {
    categories: {
      type: Array,
      required: true    // 导航分类数据，必填
    },
    currentTheme: {
      type: String,
      required: true    // 当前主题，必填
    },
    searchQuery: {
      type: String,
      default: ''      // 搜索关键词，可选
    },
    collapsedCategories: {
      type: Array,
      default: () => [] // 已折叠的分类列表，默认为空数组
    },
    targetCategory: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 15
    }
  },
  computed: {
    // 根据搜索关键词过滤分类和链接
    filteredCategories() {
      if (!this.searchQuery) {
        return this.categories
      }
      const query = this.searchQuery.toLowerCase()
      return this.categories.map(category => {
        // 过滤链接：匹配名称、URL或描述
        const filteredLinks = category.links.filter(link => {
          return link.name.toLowerCase().includes(query) ||
                 link.url.toLowerCase().includes(query) ||
                 (link.description && link.description.toLowerCase().includes(query))
        })
        // 只返回包含匹配链接的分类
        if (filteredLinks.length > 0) {
          return {
            ...category,
            links: filteredLinks
          }
        }
        return null
      }).filter(category => category !== null)
    },
    paginatedCategories() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredCategories.slice(start, end);
    }
  },
  methods: {
    // 切换分类的展开/折叠状态
    toggleCategory(categoryName) {
      this.$emit('toggle-category', categoryName)
    },
    // 检查分类是否已折叠
    isCollapsed(categoryName) {
      return this.collapsedCategories.includes(categoryName)
    },
    // 获取网站favicon图标URL
    getFaviconUrl(url) {
      try {
        const domain = new URL(url).hostname;
        return `https://www.google.com/s2/favicons?domain=${domain}&sz=32`;
      } catch {
        return '/default-favicon.ico';
      }
    },
    // 获取链接自带的图标
    getLinkIcon(url) {
      // 在导航数据中查找对应的链接
      for (const category of this.categories) {
        const link = category.links.find(link => link.url === url);
        if (link && link.icon) {
          return link.icon;
        }
      }
      return null;
    },
    // 处理图片加载错误，尝试备用方法
    handleImageError(event, url) {
      try {
        // 1. 先尝试使用链接自带的图标
        const customIcon = this.getLinkIcon(url);
        if (customIcon) {
          event.target.src = customIcon;
          return;
        }

        // 2. 再尝试获取网站根目录的 favicon.ico
        const urlObj = new URL(url);
        event.target.src = `${urlObj.protocol}//${urlObj.hostname}/favicon.ico`;
      } catch {
        event.target.src = '/default-favicon.ico';
      }
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    // 跳转到指定分类
    scrollToCategory(categoryName) {
      if (!categoryName) return;
      
      // 查找分类在过滤后列表中的索引
      const categoryIndex = this.filteredCategories.findIndex(
        category => category.name === categoryName
      );
      
      if (categoryIndex === -1) return;
      
      // 计算目标分类所在的页码
      const targetPage = Math.floor(categoryIndex / this.pageSize) + 1;
      
      // 如果需要切换页面
      if (this.currentPage !== targetPage) {
        this.currentPage = targetPage;
      }
      
      // 等待 DOM 更新后滚动到目标分类
      this.$nextTick(() => {
        const element = document.querySelector(`[data-category="${categoryName}"]`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  },
  watch: {
    // 监听目标分类的变化
    targetCategory: {
      handler(newValue) {
        this.scrollToCategory(newValue);
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.navigation {
  padding: 20px;
  margin-top: 20px;
  transition: all 0.3s ease;
}

.category {
  margin-bottom: 30px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.category-title {
  margin: 0;
  padding: 15px 20px;
  font-size: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: transparent;
}

.category-title:hover {
  background-color: var(--el-color-primary-light-9);
}

.dark-theme .category-title:hover {
  background-color: #2c2c2c;
}

.toggle-icon {
  margin-left: auto;
  transition: transform 0.3s;
}

.toggle-icon.is-active {
  transform: rotate(-180deg);
}

.links-container {
  padding: 15px 15px;
  transition: all 0.3s ease;
}

ul {
  list-style: none;
  padding: 15px;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 12px;
}

a {
  flex: 1;
}

.el-button {
  width: 100%;
  height: 45px;
  justify-content: flex-start;
  font-size: 16px;
}

.favicon {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  vertical-align: middle;
}

/* 动画效果 */
.category-title .el-icon {
  transition: transform 0.3s;
}

.category-title:hover .el-icon {
  transform: scale(1.2);
}

.el-button {
  transition: all 0.2s ease;
}

.el-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* 暗黑模式样式 */
.navigation.dark-theme {
  background-color: #1a1a1a;
  color: #ffffff;
}

.dark-theme .category-title {
  color: #ffffff;
}

.dark-theme .el-button.el-button--primary {
  background-color: #2c2c2c;
  border-color: #3f3f3f;
  color: #ffffff;
}

.dark-theme .el-button.el-button--primary:hover {
  background-color: #3f3f3f;
  border-color: #4f4f4f;
  color: #409EFF;
}

/* 分页器暗黑模式样式 */
.dark-theme :deep(.el-pagination) {
  --el-pagination-bg-color: #2c2c2c;
  --el-pagination-text-color: #ffffff;
  --el-pagination-button-color: #ffffff;
  --el-pagination-hover-color: #409eff;
  --el-pagination-button-disabled-color: #606266;
  --el-pagination-button-bg-color: #2c2c2c;
  --el-pagination-button-disabled-bg-color: #1a1a1a;
}

.dark-theme :deep(.el-pagination .el-pager li) {
  background-color: #2c2c2c;
  color: #ffffff;
}

.dark-theme :deep(.el-pagination .el-pager li:hover) {
  color: #409eff;
}

.dark-theme :deep(.el-pagination .el-pager li.active) {
  background-color: #409eff;
  color: #ffffff;
}

.dark-theme :deep(.el-pagination .btn-prev),
.dark-theme :deep(.el-pagination .btn-next) {
  background-color: #2c2c2c;
  color: #ffffff;
}

.dark-theme :deep(.el-pagination .btn-prev:hover),
.dark-theme :deep(.el-pagination .btn-next:hover) {
  color: #409eff;
}

.dark-theme :deep(.el-pagination .btn-prev.disabled),
.dark-theme :deep(.el-pagination .btn-next.disabled) {
  background-color: #1a1a1a;
  color: #606266;
}

.subcategory-links {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 15px;
}

.dark-theme .subcategory-title {
  color: var(--el-text-color-primary);
}

@media (max-width: 768px) {
  .navigation {
    padding: 10px;
    margin-top: 10px;
  }

  ul {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 8px;
    padding: 10px;
  }

  .el-button {
    font-size: 14px;
    height: 40px;
  }

  .favicon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}
</style>
