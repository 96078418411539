// 汉字转拼音首字母映射表
const pinyinMap = {
  '阿': 'a', '啊': 'a', '爱': 'a', '安': 'a', '按': 'a',
  '百': 'b', '白': 'b', '帮': 'b', '报': 'b', '比': 'b', '必': 'b',
  '测': 'c', '查': 'c', '常': 'c', '成': 'c', '程': 'c',
  '的': 'd', '第': 'd', '点': 'd', '电': 'd', '都': 'd',
  '儿': 'e', '而': 'e', '二': 'e',
  '发': 'f', '法': 'f', '方': 'f', '分': 'f', '服': 'f',
  '高': 'g', '工': 'g', '公': 'g', '共': 'g', '购': 'g',
  '好': 'h', '号': 'h', '后': 'h', '会': 'h', '活': 'h',
  '机': 'j', '技': 'j', '加': 'j', '家': 'j', '见': 'j',
  '开': 'k', '看': 'k', '科': 'k', '可': 'k', '空': 'k',
  '来': 'l', '里': 'l', '理': 'l', '力': 'l', '联': 'l',
  '吗': 'm', '买': 'm', '美': 'm', '们': 'm', '免': 'm',
  '你': 'n', '年': 'n', '能': 'n', '女': 'n',
  '哦': 'o',
  '品': 'p', '平': 'p', '普': 'p',
  '其': 'q', '起': 'q', '气': 'q', '前': 'q', '请': 'q',
  '人': 'r', '如': 'r', '入': 'r', '软': 'r',
  '三': 's', '商': 's', '上': 's', '生': 's', '时': 's',
  '他': 't', '她': 't', '台': 't', '天': 't', '图': 't',
  '为': 'w', '我': 'w', '文': 'w', '问': 'w', '无': 'w',
  '小': 'x', '校': 'x', '新': 'x', '行': 'x', '学': 'x',
  '也': 'y', '一': 'y', '用': 'y', '有': 'y', '员': 'y',
  '在': 'z', '站': 'z', '这': 'z', '中': 'z', '主': 'z'
};

/**
 * 获取汉字的拼音首字母
 * @param {string} char 单个汉字
 * @returns {string} 返回拼音首字母或原字符
 */
function getInitial(char) {
  return pinyinMap[char] || char;
}

/**
 * 获取字符串的拼音首字母
 * @param {string} str 输入字符串
 * @returns {string} 返回拼音首字母字符串
 */
export function getPinyinInitials(str) {
  return str.split('').map(char => getInitial(char)).join('');
}
