<template>
  <div>
    <!-- 移动端菜单切换按钮 -->
    <div class="mobile-toggle" :class="{ 'mobile-toggle-dark': isDarkTheme }" @click="toggleMobileMenu">
      <el-icon size="24">
        <Expand v-if="!isMobileMenuVisible" />
        <Fold v-else />
      </el-icon>
    </div>

    <div class="side-navigation" 
      :class="{ 
        'side-navigation-dark': isDarkTheme,
        'mobile-visible': isMobileMenuVisible
      }"
    >
      <!-- 桌面端显示LOGO -->
      <div class="side-nav-header" v-if="!isMobile">
        <div class="time-container" :class="{ 'time-container-dark': isDarkTheme }">
          <div class="date-display">{{ dateDisplay }}</div>
          <div class="time-display">{{ timeDisplay }}</div>
          <div class="week-display">{{ weekDisplay }}</div>
        </div>
      </div>

      <div class="side-nav-content">
        <div class="scroll-bottom-wrapper" style="margin-bottom: 1px;">
          <el-button 
            class="scroll-bottom-btn"
            @click="scrollToBottom"
          >
            <el-icon><ArrowDownBold /></el-icon>
            返回底部
          </el-button>
        </div>
        <div class="side-nav-search">
          <el-input
            v-model="searchText"
            placeholder="搜索分类..."
            clearable
            prefix-icon="Search"
            @input="filterCategories"
          />
          <div class="statistics-info">
            <span>当前显示: {{ filteredCategories.length }} 个分类</span>
            <span>共 {{ totalLinksCount }} 个链接</span>
          </div>
        </div>
        <el-menu
          :default-active="activeIndex"
          class="side-nav-menu"
          ref="sideMenu"
          @select="handleSelect"
        >
          <div class="menu-grid">
            <el-menu-item 
              v-for="category in filteredCategories" 
              :key="category.name" 
              :index="category.name"
              :class="{ 'is-active': activeIndex === category.name }"
            >
              <span v-if="category.emoji" class="emoji-icon">{{ category.emoji }}</span>
              <el-icon v-else>
                <component :is="category.icon || 'Folder'" />
              </el-icon>
              <span class="category-name">{{ category.name }}</span>
              <span class="item-count">{{ category.links.length }}</span>
            </el-menu-item>
          </div>
        </el-menu>
      </div>
      <div class="back-to-top-wrapper">
        <el-button 
          class="back-to-top-btn"
          @click="scrollToTop"
        >
          <el-icon><ArrowUpBold /></el-icon>
          返回顶部
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Folder, Expand, Fold, Monitor, Search, ArrowUpBold, ArrowDownBold } from '@element-plus/icons-vue'
import { navData } from "../nav-data.js"
import * as ElementPlusIcons from '@element-plus/icons-vue'
import { nextTick } from 'vue'
import { getPinyinInitials } from "../utils/pinyin.js"

export default {
  name: 'SideNavigation',
  components: {
    Folder,
    Expand,
    Fold,
    Monitor,
    Search,
    ArrowUpBold,
    ArrowDownBold,
    ...ElementPlusIcons
  },
  props: {
    isDarkTheme: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeIndex: '',
      categories: navData,
      isMobileMenuVisible: false,
      searchText: '',
      filteredCategories: navData,
      isMobile: window.innerWidth <= 768,
      currentTime: '',
      dateDisplay: '',
      timeDisplay: '',
      weekDisplay: '',
      timer: null
    }
  },
  computed: {
    totalLinksCount() {
      return this.categories.reduce((total, category) => total + category.links.length, 0);
    }
  },
  created() {
    this.updateTime()
    this.timer = setInterval(this.updateTime, 1000)
  },
  beforeUnmount() {
    // 清理事件监听
    if (this.handleResize) {
      window.removeEventListener('resize', this.handleResize);
    }
    // 清理定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    filterCategories() {
      if (!this.searchText) {
        this.filteredCategories = this.categories;
        return;
      }
      const searchLower = this.searchText.toLowerCase();
      this.filteredCategories = this.categories.filter(category => {
        const categoryName = category.name.toLowerCase();
        const pinyinInitials = getPinyinInitials(category.name).toLowerCase();
        return categoryName.includes(searchLower) || pinyinInitials.includes(searchLower);
      });
    },
    handleSelect(index) {
      this.activeIndex = index;
      // 发送选中的分类名称
      this.$emit('category-selected', index);
      // 发送清空顶部搜索框的事件
      this.$emit('clear-top-search');
      // 在移动端时，选择后自动隐藏菜单
      if (this.isMobile) {
        this.isMobileMenuVisible = false;
      }
    },
    toggleMobileMenu() {
      this.isMobileMenuVisible = !this.isMobileMenuVisible;
      // 等待 DOM 更新后再触发 resize 事件
      nextTick(() => {
        window.dispatchEvent(new Event('resize'));
      });
    },
    scrollToTop() {
      const sideContent = document.querySelector('.side-nav-content');
      if (sideContent) {
        sideContent.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    },
    scrollToBottom() {
      const sideContent = document.querySelector('.side-nav-content');
      if (sideContent) {
        sideContent.scrollTo({
          top: sideContent.scrollHeight,
          behavior: 'smooth'
        });
      }
    },
    updateTime() {
      const now = new Date()
      const year = now.getFullYear()
      const month = String(now.getMonth() + 1).padStart(2, '0')
      const day = String(now.getDate()).padStart(2, '0')
      const hours = String(now.getHours()).padStart(2, '0')
      const minutes = String(now.getMinutes()).padStart(2, '0')
      const seconds = String(now.getSeconds()).padStart(2, '0')
      const weekDays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      const weekDay = weekDays[now.getDay()]
      
      this.dateDisplay = `${year}年${month}月${day}日`
      this.timeDisplay = `${hours}:${minutes}:${seconds}`
      this.weekDisplay = weekDay
    }
  },
  mounted() {
    // 创建一个防抖函数
    const debounce = (fn, delay) => {
      let timer = null;
      return function (...args) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => fn.apply(this, args), delay);
      };
    };

    // 处理 resize 事件
    const handleResize = debounce(() => {
      if (window.innerWidth > 768 && !this.isMobileMenuVisible) {
        this.isMobileMenuVisible = false;
      }
      this.isMobile = window.innerWidth <= 768
    }, 100);

    window.addEventListener('resize', handleResize);

    // 保存引用以便清理
    this.handleResize = handleResize;
  },

}
</script>

<style scoped>
.mobile-toggle {
  display: none;
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 2000;

  border-radius: 4px;
  background-color: var(--el-bg-color);
  box-shadow: var(--el-box-shadow-lighter);
  cursor: pointer;
  transition: all 0.3s;
  color: #333333;
}

.mobile-toggle-dark {
  color: #ffffff;
  background-color: #1a1a1a;
  border-color: #2c2c2c;
}

@media screen and (max-width: 768px) {
  .mobile-toggle {
    display: block;
  }

  .side-navigation {
    position: fixed;
    left: -360px;
    top: 60px !important; /* 调整顶部位置，避免被头部导航栏遮挡 */
    height: calc(100vh - 60px) !important; /* 调整高度 */
    transition: left 0.3s ease;
    transform: translateX(0);
  }

  .side-navigation.mobile-visible {
    left: 0;
    transform: translateX(0);
  }
}

.side-navigation {
  width: 360px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  background-color: var(--el-bg-color);
  border-right: 1px solid var(--el-border-color-light);
  transition: transform 0.3s ease;
  overflow-x: hidden;
}

.side-navigation-dark {
  background-color: #1a1a1a;
  border-color: #2c2c2c;
}

.side-nav-header {
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.time-container {
  padding: 6px;
  text-align: center;


}

.time-container-dark {
  color: #a8a8a8;
}

.date-display {
  font-size: 14px;
  color: var(--el-text-color-regular);
}

.time-display {
  font-size: 24px;
  font-weight: bold;
  color: var(--el-text-color-primary);
}

.week-display {
  font-size: 11px;
  color: var(--el-text-color-secondary);
}

.side-nav-content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
}

.side-nav-search {
  padding: 16px;
}

.side-nav-search :deep(.el-input__wrapper) {
  background-color: transparent;
}

.side-nav-search :deep(.el-input__inner) {
  height: 32px;
  font-size: 14px;
}

.statistics-info {
  padding: 8px 0;
  font-size: 13px;
  color: #666;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}

.side-navigation-dark .statistics-info {
  color: #a3a6ad;
  border-bottom-color: #2c2c2c;
}

.side-nav-menu {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  border-right: none;
  background-color: transparent;
}

.menu-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
  width: 100%;
}

.side-nav-menu :deep(.el-menu-item) {
  height: 40px;
  line-height: 40px;
  padding: 0 8px;
  margin: 1px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: var(--el-text-color-primary);
}

.side-nav-menu :deep(.el-menu-item.is-active) {
  background-color: var(--el-color-primary-light-9);
  color: var(--el-color-primary);
}

.side-navigation-dark .side-nav-menu :deep(.el-menu-item) {
  color: #fff;
}

.category-name {
  margin: 0 4px;
  flex: 1;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}

.item-count {
  margin-left: 4px;
  font-size: 11px;
  padding: 0 2px;
  min-width: 16px;
  text-align: center;
  flex-shrink: 0;
  color: var(--el-text-color-secondary);
}

.emoji-icon {
  margin-right: 6px;
  font-size: 14px;
  flex-shrink: 0;
}

.side-nav-menu :deep(.el-menu-item .el-icon) {
  margin-right: 6px;
  font-size: 14px;
  flex-shrink: 0;
  color: inherit;
}

.side-nav-menu :deep(.el-menu-item:hover) {
  background-color: var(--el-color-primary-light-9);
}

.side-navigation-dark .side-nav-menu :deep(.el-menu-item:hover) {
  background-color: var(--el-color-primary-light-3);
}

/* 在移动端时切换回单列显示 */
@media screen and (max-width: 768px) {
  .menu-grid {
    grid-template-columns: 1fr;
  }
}

.back-to-top-wrapper {
  position: sticky;
  bottom: 0;
  padding: 16px;
  margin-top: auto;
  background: #ffffff;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  transition: all 0.3s ease;
}

.side-navigation-dark .back-to-top-wrapper {
  background: #1a1a1a;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);
}

.back-to-top-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #f5f7fa;
  border: 1px solid #dcdfe6;
  color: #303133;
  transition: all 0.3s ease;
}

.back-to-top-btn:hover {
  background: #ecf5ff;
}

.side-navigation-dark .back-to-top-btn {
  background: #2c2c2c;
  border-color: #4c4c4c;
  color: #e5eaf3;
}

.side-navigation-dark .back-to-top-btn:hover {
  background: #409eff;
  border-color: #409eff;
  color: white;
}

:deep(.dark) .back-to-top-wrapper {
  background: #1a1a1a;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);
}

.scroll-bottom-wrapper {
  padding: 8px 16px;
}

.scroll-bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #f5f7fa;
  border: 1px solid #dcdfe6;
  color: #303133;
  transition: all 0.3s ease;
}

.scroll-bottom-btn:hover {
  background: #ecf5ff;
}

.side-navigation-dark .scroll-bottom-btn {
  background: #2c2c2c;
  border-color: #4c4c4c;
  color: #e5eaf3;
}

.side-navigation-dark .scroll-bottom-btn:hover {
  background: #409eff;
  border-color: #409eff;
  color: white;
}

.main-content {
  margin-left: 360px;
  width: calc(100% - 360px);
  min-height: 100vh;
  transition: margin-left 0.3s ease, width 0.3s ease;
}

@media screen and (max-width: 768px) {
  .side-navigation {
    width: 300px;
    transform: translateX(-100%);
  }
  
  .side-navigation.mobile-visible {
    transform: translateX(0);
  }

  .main-content {
    margin-left: 0;
    width: 100%;
  }

  .main-content.menu-visible {
    margin-left: 300px;
    width: calc(100% - 300px);
  }
}
</style>
