export const designResources = [
  {
    "name": "设计资源",
    "icon": "Brush",
    "emoji": "🎨",
    "links": [
      {"name": "Dribbble","url": "https://dribbble.com","description": "全球领先的设计师社区和作品展示平台。"},
      {"name": "站酷","url": "https://www.zcool.com.cn/","description": "中国领先的创意设计平台。"},
      {"name": "Behance","url": "https://www.behance.net","description": "Adobe旗下的创意作品展示平台。"},
      {"name": "UI中国","url": "https://www.ui.cn","description": "中国专业的用户体验设计平台。"},
      {"name": "Figma","url": "https://www.figma.com","description": "专业的在线UI设计工具。"},
      {"name": "Unsplash","url": "https://unsplash.com","description": "高质量免费图片素材库。"},
      {"name": "IconFont","url": "https://www.iconfont.cn","description": "阿里巴巴矢量图标库。"},
      {"name": "Flaticon","url": "https://www.flaticon.com","description": "免费矢量图标下载平台。"},
      {"name": "Font Awesome","url": "https://fontawesome.com","description": "流行的图标字体库。"},
      {"name": "3D溜溜网","url": "https://www.3d66.com/","description": "3D溜溜网下载"},
      {"name": "Houzz","url": "https://www.houzz.com/","description": "全球知名的家居设计平台"},
      {"name": "Pinterest","url": "https://www.pinterest.com/","description": "一个灵感分享平台，用户可以找到大量的室内设计和家居装修的创意，适合用来收集设计灵感和概念。"},
      {"name": "ArchDaily","url": "https://www.archdaily.com/","description": "全球知名的建筑设计平台"},
      {"name": "Elle Decor","url": "https://www.elledecor.com/","description": "是一本致力于高端室内设计和家居的杂志，网站上有丰富的室内设计灵感和趋势，适合寻找精致、豪华的设计风格。"},
      {"name": "Electrical4u","url": "https://www.electrical4u.com/","description": "专业的电气设计平台，提供电气系统设计、电子系统设计、电子元件选择等服务。"},
      {"name": "千图网","url": "https://www.58pic.com/","description": "中国领先的图库平台，提供高质量的照片、矢量图、图标和设计资源。"},
      {"name": "Pixabay","url": "https://pixabay.com/","description": "一个免费的图片素材库，提供大量 Creative Commons 许可的图片。"},
      {"name": "设计导航","url": "https://hao.shejidaren.com/","description": "设计导航网站"},
      {"name": "UXPin","url": "https://www.uxpin.com/","description": "一个专业的用户体验设计平台，提供设计原型、交互设计、交互测试等功能。"},
      {"name": "奇迹秀","url": "https://www.qijishow.com/","description": "设计资源"},
    ]
  },
  {
    "name": "3D 工具",
    "icon": "Brush",
    "emoji": "🛖",
    "links": [
    {"name": "Blender","url": "https://www.blender.org","description": "开源的3D模型编辑器。"},
    {"name": "SweetHome3D","url": "https://www.sweethome3d.com/","description": "免费的家庭建模软件。"},
    {"name": "Cinema 4D","url": "https://www.maxon.net/en/cinema-4d","description": "专业的3D动画软件。"},
    {"name": "Maya","url": "https://www.autodesk.com/products/maya/overview","description": "专业的3D动画软件。"},
    {"name": "3DS Max","url": "https://www.autodesk.com/products/3ds-max/overview","description": "专业的3D动画软件。"},
    {"name": "Autodesk Maya","url": "https://www.autodesk.com/products/maya/overview","description": "专业的3D动画软件。"},
    {"name": "Autodesk 3DS Max","url": "https://www.autodesk.com/products/3ds-max/overview","description": "专业的3D动画软件。"},

    ]
  },

  {
    "name": "图像工具",
    "icon": "Brush",
    "emoji": "🖍",
    "links": [
    {"name": "Adobe XD","url": "https://www.adobe.com/products/xd.html","description": "Adobe的矢量图设计软件。"},
    {"name": "Figma","url": "https://www.figma.com/","description": "在线的矢量图设计软件。"},
    {"name": "Sketch","url": "https://www.sketch.com/","description": "Mac上的矢量图设计软件。"},
    {"name": "GIMP","url": "https://www.gimp.org","description": "免费的图像编辑软件。"},
    {"name": "Inkscape","url": "https://inkscape.org","description": "开源的矢量图编辑器。"},
    {"name": "Blender","url": "https://www.blender.org","description": "开源的3D模型编辑器。"},
    {"name": "FreeFileSync","url": "https://freefilesync.org","description": "跨平台的文件同步工具。"},
    {"name": "Krita","url": "https://krita.org/zh-cn/","description": "免费的矢量图编辑软件。"},
    {"name": "Darktable","url": "https://www.darktable.org/","description": "开源的照片和图像编辑软件。"},
    {"name": "Penpot","url": "https://penpot.app/","description": "开源的在线协作工具。"},
    {"name": "SweetHome3D","url": "https://www.sweethome3d.com/","description": "免费的家庭建模软件。"},
    {"name": "Snipaste","url": "https://zh.snipaste.com/","description": "免费的截图工具。"},
    {"name": "LibreCAD","url": "https://librecad.org/","description": "LibreCAD 是一款适用于 Windows、Apple 和 Linux 的免费开源 CAD 应用程序。支持和文档来自我们庞大的、专门的用户、贡献者和开发人员社区。"},
    {"name": "Audacity","url": "https://www.audacityteam.org/","description": "免费的音频编辑软件。"},
  ] 
  },
  {
    "name": "视频工具",
    "icon": "Brush",
    "emoji": "🎞",
    "links": [
    {"name": "HandBrake","url": "https://handbrake.fr/","description": "免费的视频转码软件。"},
    {"name": "剪映","url": "https://www.capcut.cn/","description": "在线视频编辑，支持AI特效和字幕生成"},
    {"name": "剪映","url": "https://www.capcut.com/","description": "在线视频编辑，支持AI特效和字幕生成"},
    {"name": "ffmpeg","url": "https://www.ffmpeg.org/","description": "开源的视频转码软件。"},
    {"name": "Audacity","url": "https://www.audacityteam.org/","description": "免费的音频编辑软件。"},
  ] 
  },
  {
    "name": "音频工具",
    "icon": "Brush",
    "emoji": "▶",
    "links": [
    {"name": "HandBrake","url": "https://handbrake.fr/","description": "免费的视频转码软件。"},
    {"name": "剪映","url": "https://www.capcut.cn/","description": "在线视频编辑，支持AI特效和字幕生成"},
    {"name": "剪映","url": "https://www.capcut.com/","description": "在线视频编辑，支持AI特效和字幕生成"},
    {"name": "ffmpeg","url": "https://www.ffmpeg.org/","description": "开源的视频转码软件。"},
    {"name": "Audacity","url": "https://www.audacityteam.org/","description": "免费的音频编辑软件。"},
  ] 
  },
  
];