export const f = [
    {
    name: '其他',
    icon: 'Trophy',
    emoji: '🎮',
    links: [
        {"name": "GnuCash","url": "https://www.gnucash.org/","description": "开源个人和小规模企业财务软件"},
        {"name": "screentogif","url": "https://www.screentogif.com/","description": "开源屏幕录制"},
        {"name": "RustDesk","url": "https://rustdesk.com/","description": "开源远程协助"},
        {"name": "Home Assistant","url": "https://www.home-assistant.io/","description": "开源家庭自动化"},
        {"name": "emojiall","url": "https://www.emojiall.com/zh-hans/emojis/","description": "emoji"},
    ]
    },
    {
        name: 'Android',
        icon: 'Trophy',
        emoji: '🎮',
        links: [
            {"name": "雷电模拟器","url": "https://www.ldmnq.com/","description": "安卓模拟器"},
            {"name": "夜神模拟器","url": "https://www.yeshen.com/","description": "安卓模拟器"},
            {"name": "Goole Play","url": "https://play.google.com/","description": "谷歌商店"},
            
        ]
    },
    {
        name: '威客网站',
        icon: 'Trophy',
        emoji: '📡',
        links: [
            {"name": "猪八戒","url": "https://www.zbj.com/","description": "猪八戒网"},
            { "name": "Freelancer", "url": "https://www.freelancer.com/", "description": "全球知名的自由职业平台，提供从设计、编程到写作、营销等各种项目，雇主和自由职业者可以根据需求合作。" },
  { "name": "Upwork", "url": "https://www.upwork.com/", "description": "一个大型自由职业平台，提供各种类别的工作机会，包括编程、设计、写作、客服、市场营销等。" },
  { "name": "Fiverr", "url": "https://www.fiverr.com/", "description": "专注于提供低成本服务的自由职业平台，用户可以根据自己的需求购买小额任务，如设计、写作、翻译等。" },
  { "name": "Toptal", "url": "https://www.toptal.com/", "description": "提供顶级自由职业者的平台，专注于软件开发、金融和设计领域的高端人才，要求较高的专业技能。" },
  { "name": "Guru", "url": "https://www.guru.com/", "description": "提供自由职业项目发布和承接的平台，涵盖编程、设计、写作、翻译等多个领域，用户可以发布长期或短期任务。" },
  { "name": "PeoplePerHour", "url": "https://www.peopleperhour.com/", "description": "一个提供各种项目和任务外包的威客平台，主要面向自由职业者，任务类型包括网页开发、设计、内容创作等。" },
  { "name": "99designs", "url": "https://99designs.com/", "description": "专注于设计类项目的威客平台，用户可以发布Logo设计、网页设计、品牌设计等需求，设计师提交作品并竞争任务。" },
  { "name": "WeWorkRemotely", "url": "https://weworkremotely.com/", "description": "一个专注于远程工作的自由职业平台，提供全球范围内的远程工作机会，适合程序员、设计师等领域的自由职业者。" },
  { "name": "DesignCrowd", "url": "https://www.designcrowd.com/", "description": "设计类威客平台，用户可以发布设计需求，收到全球设计师的创意并选择最佳作品，适合Logo设计、网站设计等。" },
  { "name": "SimplyHired", "url": "https://www.simplyhired.com/", "description": "提供多种职位的招聘和外包任务，包括自由职业者和全职工作，适合各行业的工作者。" },
  { "name": "TaskRabbit", "url": "https://www.taskrabbit.com/", "description": "一个本地化的威客平台，用户可以发布家庭任务和个人事务的需求，帮助雇主找到合适的自由职业者进行任务处理。" },
  { "name": "CrowdSPRING", "url": "https://www.crowdspring.com/", "description": "一个创意类的威客平台，提供设计、品牌和广告创作类的任务，雇主可以看到设计师提交的作品并选择最适合的。" },
  { "name": "Gigster", "url": "https://www.gigster.com/", "description": "一个高端技术项目外包平台，专注于IT、软件开发和产品设计等领域，提供优质的自由职业者服务。" },
  { "name": "Hubstaff Talent", "url": "https://talent.hubstaff.com/", "description": "一个免费的外包平台，提供自由职业者和外包公司的数据库，帮助雇主找到合适的开发者、设计师和营销人员。" },
  { "name": "Remotive", "url": "https://remotive.io/", "description": "提供全球远程工作机会的平台，适合程序员、设计师、市场营销人员等，帮助求职者找到合适的远程职位。" },
  { "name": "AngelList", "url": "https://angel.co/", "description": "一个为初创公司提供人才招聘的威客平台，用户可以找到创业公司项目，适合开发者、设计师和运营人员。" },
  { "name": "Outsourcely", "url": "https://www.outsourcely.com/", "description": "一个远程工作平台，提供长期雇佣的自由职业机会，适合技术开发、设计、写作等职位，强调稳定性。" },
        ]
    },
    {
        name: '书籍阅读',
        icon: 'Trophy',
        emoji: '📖',
        links: 
        [
        {"name": "微信读书","url": "https://weread.qq.com/","description": "微信读书"},
        {"name": "中国知网","url": "https://www.cnki.net/","description": "中国知网"},
        {"name": "中国国家图书馆","url": "https://www.nlc.cn/web/index.shtml","description": "中国国家图书馆"},
        {"name": "Internet Archive", "url": "https://archive.org/", "description": "全球最大数字档案馆，提供海量的历史文献、书籍、网站、电影、音频等资源"},
        {"name": "Project Gutenberg", "url": "https://www.gutenberg.org/", "description": "提供超过6万本免费的电子书，包含大量历史文献、文学作品等"},
        {"name": "National Archives", "url": "https://www.archives.gov/", "description": "美国国家档案馆，提供美国政府的历史文件、档案和重要历史文献"},
        {"name": "欧洲数字图书馆 (Europeana)", "url": "https://www.europeana.eu/", "description": "欧洲数字图书馆，提供欧洲各国的文化和历史遗产、文献、图片等资源"},
        {"name": "The British Library", "url": "https://www.bl.uk/", "description": "英国国家图书馆，提供大量历史档案、古籍、手稿、地图、音频等文献资源"},
        {"name": "World Digital Library", "url": "https://www.wdl.org/", "description": "世界数字图书馆，提供全球重要历史文献、文化遗产和档案资料的数字化资源"},
        {"name": "HathiTrust Digital Library", "url": "https://www.hathitrust.org/", "description": "提供全球范围内的数字图书、历史文献、期刊、学术资源等内容的开放存取平台"},
        {"name": "Library of Congress", "url": "https://www.loc.gov/", "description": "美国国会图书馆，提供大量的美国历史、文化、政府档案等资源"},
        {"name": "Digital Public Library of America (DPLA)", "url": "https://dp.la/", "description": "美国数字公共图书馆，提供美国历史的数字档案、图片、手稿、录音等资源"},
        {"name": "UN Digital Library", "url": "https://digitallibrary.un.org/", "description": "联合国数字图书馆，提供联合国的官方文献、历史档案、决议、协议等资料"},

        ]
    },
    {
        name: '在线工具',
        icon: 'Trophy',
        emoji: '🔧',
        links: [
            {"name": "MyIP地址查询","url": "https://www.myip.com/","description": "IP地址查询"},
            {"name": "IP地址查询","url": "https://www.ipip.net/","description": "IP地址查询"},
            {"name": "Whois查询","url": "https://www.whois.com/","description": "Whois查询"},
            {"name": "ICANN Whois查询","url": "https://lookup.icann.org/","description": "ICANN Whois查询"},
            {"name": "IP信息查询","url": "https://ipinfo.io/","description": "IP信息查询"},
            { "name": "Canva", "url": "https://www.canva.com/", "description": "一款免费的在线图形设计工具，适用于制作海报、社交媒体图片、PPT等设计作品" },
            { "name": "TinyPNG", "url": "https://tinypng.com/", "description": "一个在线图片压缩工具，可以无损压缩PNG和JPEG格式的图片" },
            { "name": "Fotor", "url": "https://www.fotor.com/", "description": "在线图像编辑工具，提供强大的修图、拼图、海报设计等功能" },
            { "name": "Google Docs", "url": "https://docs.google.com/", "description": "Google提供的在线文档处理工具，支持实时多人协作和文件共享" },
            { "name": "Wix", "url": "https://www.wix.com/", "description": "一个在线网站构建平台，提供拖放式的网页设计功能，适合不懂代码的用户使用" },
            { "name": "Grammarly", "url": "https://www.grammarly.com/", "description": "在线语法检查工具，帮助用户检查和改进英语写作中的语法、拼写和风格" },
            { "name": "Loom", "url": "https://www.loom.com/", "description": "一个在线视频录制工具，可以录制屏幕、摄像头和音频，适用于团队沟通和教学" },
            { "name": "PDFescape", "url": "https://www.pdfescape.com/", "description": "一个免费的在线PDF编辑工具，提供编辑、填写表单和注释等功能" },
            { "name": "JotForm", "url": "https://www.jotform.com/", "description": "在线表单制作工具，允许用户创建各种类型的表单、调查问卷等" },
            { "name": "Trello", "url": "https://trello.com/", "description": "基于看板的在线项目管理工具，适用于团队任务分配和进度跟踪" },
            { "name": "Zamzar", "url": "https://www.zamzar.com/", "description": "一个支持多种文件格式转换的在线工具，提供文档、图片、音频、视频格式的转换" },
            { "name": "Speechify", "url": "https://www.speechify.com/", "description": "在线文字转语音工具，支持多种语言和音调，可以将文章和文档转换为语音" },
            { "name": "条形码扫描", "url": "https://products.groupdocs.app/zh/scanner/scan-barcode", "description": "条形码扫描" },
 

        ]
    },
    {
    name: '汽车数码',
    icon: 'Trophy',
    emoji: '🚗',
    links: [
        {"name": "汽车之家","url": "https://www.autohome.com.cn/","description": "汽车之家"},
        {"name": "懂车帝","url": "https://www.dongchedi.com/","description": "懂车帝"},
        {"name": "易车","url": "https://www.yiche.com/","description": "易车"},
        {"name": "太平洋科技","url": "https://www.pconline.com.cn/","description": "太平洋科技"},
        {"name": "Car and Driver", "url": "https://www.caranddriver.com/", "description": "美国汽车媒体网站，提供最新汽车新闻、评测、购买建议和技术分析"},
        {"name": "Motor Trend", "url": "https://www.motortrend.com/", "description": "提供汽车新闻、评测、视频、购买建议、汽车比赛和技术趋势的综合网站"},
        {"name": "Autoblog", "url": "https://www.autoblog.com/", "description": "提供全球汽车新闻、车评、行业动态以及车辆技术和创新的内容"},
        {"name": "Edmunds", "url": "https://www.edmunds.com/", "description": "汽车评测网站，提供汽车价格、购车建议、用户评价和市场动态等信息"},
        {"name": "Top Gear", "url": "https://www.topgear.com/", "description": "英国知名汽车节目和媒体，提供汽车评测、新闻、娱乐内容和车主指南"},
        {"name": "Motor1", "url": "https://www.motor1.com/", "description": "全球汽车新闻网站，提供汽车评测、购买指南、车展报道和行业动态"},
        {"name": "Autotrader", "url": "https://www.autotrader.com/", "description": "汽车买卖平台，提供新车和二手车的购买、报价、评测等信息"},
        {"name": "Jalopnik", "url": "https://jalopnik.com/", "description": "汽车新闻与文化网站，关注汽车行业的新闻、科技创新和汽车文化"},
        {"name": "Carwow", "url": "https://www.carwow.co.uk/", "description": "英国的汽车购买平台，提供新车的比较、报价和购买建议"}
   
   
    ]
    },
    {
        name: '求职招聘',
        icon: 'Trophy',
        emoji: '📖',
        links: [
        {"name": "智联招聘", "url": "https://www.zhaopin.com/", "description": "中国领先的职业发展平台，提供招聘、求职等服务"},
        {"name": "前程无忧 (51Job)", "url": "https://www.51job.com/", "description": "提供招聘信息、求职服务以及职场资讯"},
        {"name": "拉勾网", "url": "https://www.lagou.com/", "description": "专注于互联网行业的招聘平台，提供技术、产品、运营等岗位"},
        {"name": "BOSS直聘", "url": "https://www.zhipin.com/", "description": "提供直接与招聘方对接的求职招聘平台"},
        {"name": "英才网", "url": "https://www.yingcai.com/", "description": "中国较大的招聘信息平台之一，提供全面的求职服务"},
        {"name": "大街网", "url": "https://www.dajie.com/", "description": "基于社交的求职招聘平台，结合用户网络推荐岗位"},
        {"name": "应届生求职网", "url": "https://www.yingjiesheng.com/", "description": "专为应届毕业生提供招聘信息的求职平台"},
        {"name": "实习僧", "url": "https://www.shixiseng.com/", "description": "专注于为大学生提供实习机会的招聘平台"}
        
        ]
    },
    {
        name: '科技信息',
        icon: 'Trophy',
        emoji: '🔭',
        links: 
        [
            {"name": "TechCrunch", "url": "https://techcrunch.com/", "description": "全球领先的科技新闻平台，提供关于新兴科技、创业公司、投资趋势等的报道"},
            {"name": "少数派", "url": "https://sspai.com/", "description": "科技新闻网站，关注科技产品、虚拟现实、人工智能、机器人等领域的最新动态"},
            {"name": "WIRED", "url": "https://www.wired.com/", "description": "专注于科技、文化、商业和社会趋势的新闻网站，提供深入分析和科技创新的报道"},
            {"name": "The Verge", "url": "https://www.theverge.com/", "description": "科技新闻网站，关注科技产品、虚拟现实、人工智能、机器人等领域的最新动态"},
            {"name": "Ars Technica", "url": "https://arstechnica.com/", "description": "提供深度的科技新闻、硬件评测、软件分析以及IT行业动态"},
            {"name": "CNET", "url": "https://www.cnet.com/", "description": "科技产品评测和技术新闻网站，提供科技产品购买建议、新闻和技术趋势分析"},
            {"name": "Engadget", "url": "https://www.engadget.com/", "description": "科技新闻网站，涵盖电子产品、科技创新、虚拟现实、AI等领域的报道"},
            {"name": "ZDNet", "url": "https://www.zdnet.com/", "description": "面向IT专业人士的科技新闻平台，提供企业技术、软件、硬件、云计算等方面的新闻和评论"},
            {"name": "Gizmodo", "url": "https://www.gizmodo.com/", "description": "科技、设计和文化新闻网站，报道最新的科技趋势、创新产品、技术发展等"},
            {"name": "Mashable", "url": "https://mashable.com/", "description": "提供全球科技新闻、社交媒体、数字文化、娱乐、商业等领域的内容"},
            {"name": "VentureBeat", "url": "https://venturebeat.com/", "description": "专注于科技创新、AI、区块链、机器学习等领域的新闻与分析，面向技术创业者和投资人"},
            {"name": "中关村在线", "url": "https://www.zol.com.cn/", "description": ""},
          ]
          
    },
    {
        name: '军事信息',
        icon: 'Trophy',
        emoji: '🚔',
        links: [

        {"name": "GlobalSecurity", "url": "https://www.globalsecurity.org", "description": "全球安全与军事装备、战略、战争历史等信息"},
        {"name": "Military Times", "url": "https://www.militarytimes.com", "description": "美国军队新闻、军事策略、装备及退伍军人生活等"},
        {"name": "Jane's Defence", "url": "https://www.janes.com", "description": "全球防务和安全领域的新闻与分析，涵盖军事装备、武器系统等"},
        {"name": "Defense News", "url": "https://www.defensenews.com", "description": "全球防务新闻、军事技术分析、政府防务开支等"},
        {"name": "The Diplomat", "url": "https://thediplomat.com", "description": "专注于亚太地区的地缘政治和军事分析，涵盖亚洲各国的军事和安全问题"},
        {"name": "Strategic Defence Intelligence", "url": "https://www.strategicdefenceintelligence.com", "description": "全球防务市场的研究报告和分析，包括军事技术、武器系统等"},
        {"name": "Army Recognition", "url": "https://www.armyrecognition.com", "description": "军事新闻、武器系统、军事装备等，专注于全球陆军动态"},
        {"name": "Military", "url": "https://www.military.com", "description": "美国军队的新闻、军事装备、武器、政策等，涉及现役军人、退伍军人"},
        {"name": "Modern War Institute", "url": "https://mwi.usma.edu", "description": "美国西点军校的研究机构，专注于现代战争的研究与战略"},     
        ]
    },

    {
        name: '银行支付',
        icon: 'Trophy',
        emoji: '🏦',
        links: [
            {"name": "工商银行","url": "https://www.icbc.com.cn/","description": "工商银行"},
            {"name": "建设银行","url": "https://www.ccb.com/","description": "建设银行"},
            {"name": "农业银行","url": "https://www.abchina.com/","description": "农业银行"},
            {"name": "中国银行","url": "https://www.boc.cn/","description": "中国银行"},
            {"name": "交通银行","url": "https://www.bankcomm.com/","description": "交通银行"},
            {"name": "招商银行","url": "https://www.cmbchina.cn/","description": "招商银行"},
            {"name": "光大银行","url": "https://www.cebbank.com/","description": "光大银行"},
            {"name": "浦发银行","url": "https://www.spdb.com.cn/","description": "浦发银行"},
            { name: 'bewildcard',url:'https://bewildcard.com/',description:'虚拟银行卡'},
            { name: 'PayPal',url:'https://www.paypal.com/',description:'PayPal'},
            { name: 'Wechat',url:'https://pay.weixin.qq.com/',description:'微信支付'},
            { name: 'Alipay',url:'https://www.alipay.com/',description:'支付宝'},
          
        ]
    },

    {
        name: '旅游信息',
        icon: 'Trophy',
        emoji: '⛲',
        links: [
        {"name": "携程网", "url": "https://www.ctrip.com/", "description": "提供机票、酒店、度假、门票、租车等一站式旅游服务平台"},
        {"name": "去哪儿网", "url": "https://www.qunar.com/", "description": "提供酒店、机票、旅游度假、门票等服务的综合性旅游平台"},
        {"name": "飞猪", "url": "https://www.fliggy.com/", "description": "阿里巴巴旗下的旅游平台，提供全球机票、酒店、度假等预定服务"},
        {"name": "同程旅游", "url": "https://www.ly.com/", "description": "提供机票、酒店、火车票、旅游度假等服务，重点关注休闲旅游"},
        {"name": "马蜂窝", "url": "https://www.mafengwo.cn/", "description": "提供旅行攻略、旅游产品预定和旅行社服务的旅游平台"},
        {"name": "驴妈妈", "url": "https://www.lvmama.com/", "description": "提供酒店预定、景点门票、度假旅游产品预定的综合旅游平台"},
        {"name": "穷游网", "url": "https://www.qyer.com/", "description": "一个针对自由行用户的旅行社区，提供全球旅游资讯和攻略"},
        {"name": "途牛网", "url": "https://www.tuniu.com/", "description": "为游客提供定制旅游、团体旅游、跟团游、自由行等产品服务"},
        {"name": "旅行社官网", "url": "https://www.trips.com/", "description": "提供国内外旅游线路、度假产品、旅游资讯等服务"},
        {"name": "Booking.com", "url": "https://www.booking.com/", "description": "全球知名的酒店预定平台，提供全球酒店、民宿、机票等服务"}

              
        ]
    },

    {
        name: '中国法律',
        icon: 'Trophy',
        emoji: '🏛',
        links: [
        
            {"name": "中国裁判文书网","url": "https://wenshu.court.gov.cn/","description": "中国裁判文书网"},
            {"name": "中国法律法规数据库","url": "https://flk.npc.gov.cn/","description": "中国法律法规数据库"},

        ]
    },
    {
        name: '食谱信息',
        icon: 'Trophy',
        emoji: '🍪',
        links: [
        {"name": "下厨房", "url": "https://www.xiachufang.com/", "description": "提供丰富的食谱、烹饪技巧、餐饮知识等内容的平台"},
        {"name": "豆果美食", "url": "https://www.douguo.com/", "description": "食谱分享平台，用户可以上传食谱，分享自己的烹饪经验"},
        {"name": "美食天下", "url": "https://www.meishichina.com/", "description": "提供全国各地的地方特色食谱，用户可以互动、评论和分享"},

        ]
    },
    {
        name: '房产信息',
        icon: 'Trophy',
        emoji: '🏠',
        links: [
            {"name": "链家网", "url": "https://www.lianjia.com/", "description": "提供二手房、新房、租房等信息，是中国领先的房地产平台之一"},
            {"name": "搜房网", "url": "https://www.fang.com/", "description": "提供新房、二手房、租房、楼盘信息以及房产资讯"},
            {"name": "安居客", "url": "https://www.anjuke.com/", "description": "提供新房、二手房、租房以及各类房产相关服务"},
            {"name": "房天下", "url": "https://www.fang.com/", "description": "提供二手房、新房、租房、楼市资讯及房地产相关数据"},
            {"name": "贝壳找房", "url": "https://www.beike.com/", "description": "提供二手房、新房、租房、商铺等房产信息的平台"},
            {"name": "我爱我家", "url": "https://www.5i5j.com/", "description": "提供新房、二手房、租房、买卖服务等，覆盖全国主要城市"},
            {"name": "房产之窗", "url": "http://www.fccw.com.cn/", "description": "提供全国范围内的房地产信息、楼盘动态和房产资讯"},
            {"name": "居然之家", "url": "https://www.juranhome.com/", "description": "专注于家居、装修及房产相关的购物和服务平台"},
            {"name": "腾讯房产", "url": "https://house.qq.com/", "description": "提供新房、二手房、租房、房产资讯、楼市数据等信息"},
            {"name": "聚焦房地产网", "url": "http://www.ju99.com/", "description": "提供全国各地的房产信息、市场动态以及楼盘资讯"},
          ]
          
    },

    {
        name: '家居装饰',
        icon: 'Trophy',
        emoji: '🏠',
        links:[
  
            {"name": "网易家居", "url": "https://home.163.com/", "description": "提供家居装修、家装设计、家居产品、家居资讯等内容"},
            {"name": "家居在线", "url": "https://www.jiaju123.com/", "description": "提供家居装饰、装修设计、家具、家居软装等综合信息"},
            {"name": "土巴兔", "url": "https://www.to8to.com/", "description": "提供家装设计、装修公司推荐、装修报价、施工监理等服务"},
            {"name": "美乐乐", "url": "https://www.meilele.com/", "description": "专业的家居电商平台，提供家具、家装材料、软装等产品"},
            {"name": "齐家网", "url": "https://www.jia.com/", "description": "提供家居装修、设计、材料、家具等全方位的家居服务"},
            {"name": "百度家居", "url": "https://home.baidu.com/", "description": "百度旗下的家居平台，提供家居产品推荐、装修设计灵感等"},
            {"name": "Houzz", "url": "https://www.houzz.com/", "description": "全球知名的家居装饰平台，提供装修灵感、设计案例、家具推荐等"},
            {"name": "宜家", "url": "https://www.ikea.cn/cn/zh/", "description": "中国最大的家居用品零售商，提供家居用品、家具、软体等"},
          ]
          
          
    },


    {
        name: '加密货币',
        icon: 'Trophy',
        emoji: '🪙',
        links:
        [
            {"name":'Bitcoin',url:'https://www.blockchain.com/',description:'比特币'},
            {"name": "CoinMarketCap", "url": "https://www.coinmarketcap.com/", "description": "提供全球加密货币的实时行情、市场数据、历史价格等"},
            {"name": "Binance", "url": "https://www.binance.com/", "description": "全球领先的加密货币交易平台，提供现货交易、期货交易、币币兑换等"},
            {"name": "CoinGecko", "url": "https://www.coingecko.com/", "description": "加密货币市场数据平台，提供价格跟踪、交易量、项目分析等"},
            {"name": "Kraken", "url": "https://www.kraken.com/", "description": "全球知名的加密货币交易所，支持法币和加密货币的交易"},
            {"name": "Coinbase", "url": "https://www.coinbase.com/", "description": "美国领先的加密货币交易平台，提供比特币、以太坊等加密资产的买卖和储存"},
            {"name": "Bitfinex", "url": "https://www.bitfinex.com/", "description": "提供加密货币交易、融资、借贷等服务的平台"},
            {"name": "BlockFi", "url": "https://www.blockfi.com/", "description": "提供加密货币存款、借贷、利息赚取等金融服务"},
            {"name": "CryptoCompare", "url": "https://www.cryptocompare.com/", "description": "提供加密货币市场行情、比较、分析工具，涵盖不同交易平台的加密货币数据"},
            {"name": "Decrypt", "url": "https://decrypt.co/", "description": "提供关于加密货币、区块链、NFT等的新闻、文章和分析"},
            {"name": "Messari", "url": "https://www.messari.io/", "description": "提供加密货币市场的研究、数据分析以及行业报告"}
          ]
          
          
    },

    {
        name: '金融信息',
        icon: 'Trophy',
        emoji: '🪙',
        links:
        [
            {"name": "Bloomberg", "url": "https://www.bloomberg.com/", "description": "全球领先的金融信息和数据提供商，提供财经新闻、市场分析、股票、债券、商品等实时数据"},
            {"name": "Reuters", "url": "https://www.reuters.com/", "description": "全球新闻与金融信息平台，提供财经新闻、股票市场、投资分析、全球经济等实时更新"},
            {"name": "Yahoo Finance", "url": "https://finance.yahoo.com/", "description": "提供股市新闻、金融数据、股票分析、个人理财工具等信息"},
            {"name": "CNBC", "url": "https://www.cnbc.com/", "description": "全球知名的财经新闻网站，提供股市新闻、投资分析、全球经济动态等"},
            {"name": "Investopedia", "url": "https://www.investopedia.com/", "description": "提供财经知识、投资策略、理财建议、股票市场分析和金融教育资源"},
            {"name": "MarketWatch", "url": "https://www.marketwatch.com/", "description": "提供全球股市行情、财经新闻、投资工具和金融分析"},
            {"name": "The Wall Street Journal", "url": "https://www.wsj.com/", "description": "美国知名财经新闻网站，提供全球金融市场、企业新闻、股票分析等内容"},
            {"name": "Morningstar", "url": "https://www.morningstar.com/", "description": "提供投资研究、基金评级、股票分析和市场数据"},
            {"name": "Bloomberg Terminal", "url": "https://www.bloomberg.com/professional/solution/bloomberg-terminal/", "description": "Bloomberg的专业服务平台，提供深度的金融市场数据、投资分析和实时行情（需订阅）"},
            {"name": "CoinMarketCap", "url": "https://www.coinmarketcap.com/", "description": "全球领先的加密货币市场数据平台，提供数字货币的实时价格、交易量、市场排名等信息"}
          ]
          
          
          
    },
    {
        name: '数据平台',
        icon: 'Trophy',
        emoji: '🔢',
        links:
        [
           
            { "name": "极客湾移动芯片排行", "url": "https://socpk.com/", "description": "芯片排行" },
            { "name": "谷歌趋势", "url": "https://trends.google.com/trends/ ", "description": "全球搜索趋势" },
            { "name": "十大品牌网CNPP", "url": "https://www.cnpp.cn/", "description": "提供各类行业排行" },
            
            { "name": "Data.gov", "url": "https://www.data.gov/", "description": "美国政府提供的开放数据平台，涵盖农业、气候、教育、能源、金融等多个领域的政府数据。" },
            { "name": "UN Data", "url": "http://data.un.org/", "description": "联合国提供的全球统计数据，涉及人口、健康、教育、环境等多个领域，帮助进行全球性数据分析。" },
            { "name": "Kaggle Datasets", "url": "https://www.kaggle.com/datasets", "description": "Kaggle是一个数据科学平台，提供大量的数据集，适合数据分析、机器学习和研究用途。" },
            { "name": "Google Public Data Explorer", "url": "https://www.google.com/publicdata/directory", "description": "Google提供的公共数据平台，允许用户浏览和分析全球不同领域的数据，包括经济、健康、教育等。" },
            { "name": "OECD Data", "url": "https://data.oecd.org/", "description": "经济合作与发展组织（OECD）提供的全球经济、社会和环境数据，适用于跨国分析和比较研究。" },
            { "name": "European Data Portal", "url": "https://www.europeandataportal.eu/", "description": "欧洲数据门户提供的开放数据平台，涵盖了欧洲各国的公共数据，旨在推动透明度和开放性。" },
            { "name": "Amazon Web Services (AWS) Public Datasets", "url": "https://registry.opendata.aws/", "description": "AWS提供的公共数据集，涵盖从天文到气候变化的多个领域，适用于大数据分析和云计算应用。" },
            { "name": "US Census Bureau", "url": "https://www.census.gov/data.html", "description": "美国人口普查局提供的关于人口、经济、住房和其他社会统计数据，帮助政府和企业做出决策。" },
            { "name": "CDC Data & Statistics", "url": "https://www.cdc.gov/nchs/data_access/","description": "美国疾病控制与预防中心（CDC）提供的健康统计数据，涵盖疾病预防、健康状况和流行病学数据。" },
            { "name": "NYC Open Data", "url": "https://opendata.cityofnewyork.us/", "description": "纽约市提供的开放数据平台，涵盖市政服务、交通、公共安全、环境等多个领域的数据，促进市民参与。" },
            { "name": "Open Data Network", "url": "https://www.opendatanetwork.com/", "description": "一个全球数据搜索平台，提供各种城市、州、国家级的开放数据，支持数据分析和对比。" },
            { "name": "GitHub (Open Data Projects)", "url": "https://github.com/topics/open-data", "description": "GitHub上的开放数据项目集合，开发者和研究人员分享数据集和分析工具，促进数据共享与协作。" },
            { "name": "OpenStreetMap", "url": "https://www.openstreetmap.org/", "description": "开源的全球地图数据平台，用户可以访问、编辑和下载地图数据，用于地理信息系统（GIS）分析。" },
            { "name": "National Center for Biotechnology Information (NCBI)", "url": "https://www.ncbi.nlm.nih.gov/", "description": "美国国家生物技术信息中心提供的生物医学领域的公共数据，包括基因组学、蛋白质、疾病等数据。" },
            { "name": "Quandl", "url": "https://www.quandl.com/", "description": "提供财经、经济和金融数据的平台，适合数据分析师、投资者、研究人员等使用，提供API接口。" },
            { "name": "Gapminder", "url": "https://www.gapminder.org/data/", "description": "Gapminder提供的全球统计数据，帮助用户理解全球发展趋势，包括收入、健康、环境等方面的变化。" },
            { "name": "CDC WONDER", "url": "https://wonder.cdc.gov/", "description": "美国CDC提供的在线数据查询系统，提供关于健康、疾病、死因、人口等公共卫生数据。" },
            { "name": "USGS Earthquake Data", "url": "https://earthquake.usgs.gov/", "description": "美国地质调查局提供的地震数据，包括地震活动、震中位置、震级等信息，支持实时和历史数据查询。" },
            { "name": "EOSDIS", "url": "https://earthdata.nasa.gov/", "description": "NASA提供的地球科学数据平台，提供气象、环境、气候变化等地球观测数据，支持科学研究与数据分析。" },
       ] 
    },

    {
        name: '健康医疗',
        icon: 'Trophy',
        emoji: '🆎',
        links:
        [
            { name: '默沙东诊疗手册', url: 'https://www.msdmanuals.cn/', description: '一个涵盖了医学所有领域成千上万主题的广泛医学信息来源。它们作为免费的公众服务提供给医疗专业人士和普罗大众。' },
            { "name": "WebMD", "url": "https://www.webmd.com/", "description": "提供医学健康信息、症状检查、疾病预防和治疗建议，广受大众欢迎的健康教育平台。" },
            { "name": "Mayo Clinic", "url": "https://www.mayoclinic.org/", "description": "全球知名的医疗机构，提供权威的疾病信息、治疗方案和健康建议，内容涵盖各类疾病、症状和健康生活。" },
            { "name": "MedlinePlus", "url": "https://medlineplus.gov/", "description": "由美国国立卫生研究院提供，包含有关药物、疾病、健康状况、治疗方法等的信息，面向公众提供健康教育资源。" },
            { "name": "Healthline", "url": "https://www.healthline.com/", "description": "提供各种医学健康信息，包括疾病症状、治疗方案、健康饮食等内容，是一个全面的健康资源网站。" },
            { "name": "The Mayo Clinic Connect", "url": "https://connect.mayoclinic.org/", "description": "由Mayo Clinic提供的社区平台，患者可以分享健康经历，讨论治疗方案和健康管理技巧，增强患者支持。" },
            { "name": "Medscape", "url": "https://www.medscape.com/", "description": "提供医学新闻、研究论文、疾病指南、药物信息等，主要面向医学专业人员和医疗行业从业者。" },
            { "name": "Health.gov", "url": "https://health.gov/", "description": "提供由美国政府发布的健康政策和公共卫生指南，包括健康生活方式、营养、运动等领域的最新建议。" },
            { "name": "PubMed", "url": "https://pubmed.ncbi.nlm.nih.gov/", "description": "美国国立医学图书馆提供的免费医学文献数据库，涵盖生物医学研究的各个领域，适用于学术研究和临床应用。" },
            { "name": "National Health Service (NHS) Choices", "url": "https://www.nhs.uk/", "description": "英国国家健康服务网站，提供有关疾病、健康、营养、生活方式等方面的全面信息，帮助用户获得最新的健康资讯。" },
            { "name": "Cleveland Clinic", "url": "https://my.clevelandclinic.org/", "description": "全球知名的医疗机构，提供疾病预防、健康管理和医学研究方面的知识，关注各类疾病和治疗方法。" },
            { "name": "The Lancet", "url": "https://www.thelancet.com/", "description": "世界顶级医学期刊之一，提供医学研究、公共卫生政策、疾病预防等领域的最新研究成果。" },
            { "name": "Health Tips", "url": "https://www.health.com/", "description": "提供健康、营养、生活方式等领域的专家建议，涵盖运动、饮食、心理健康等方面的内容，助力健康生活。" },
            { "name": "American Heart Association (AHA)", "url": "https://www.heart.org/", "description": "美国心脏协会提供的心脏健康和预防知识，包含心血管疾病、健康饮食、锻炼和生活方式等方面的信息。" },
            { "name": "Johns Hopkins Medicine", "url": "https://www.hopkinsmedicine.org/", "description": "约翰斯·霍普金斯医学院提供的医学健康资讯，涵盖疾病预防、治疗方案、研究创新和医疗进展。" },
            { "name": "HealthFinder", "url": "https://healthfinder.gov/", "description": "美国卫生与公众服务部提供的健康指南，涵盖从疾病预防到健康管理等各类内容，帮助公众做出健康选择。" },
            { "name": "PubMed Central", "url": "https://www.ncbi.nlm.nih.gov/pmc/", "description": "由美国国立卫生研究院提供的免费医学文献库，涵盖大量的生物医学研究论文，支持临床实践和医学研究。" },
            { "name": "MedlinePlus Drug Information", "url": "https://medlineplus.gov/druginfo/", "description": "提供关于药物的信息，包括药品的使用、剂量、副作用和警告等，帮助用户了解药物的安全性和有效性。" },
            { "name": "Psychology Today", "url": "https://www.psychologytoday.com/", "description": "提供心理健康、情绪、心理疾病等领域的专业知识和资源，旨在提高公众的心理健康意识。" },
            { "name": "WebMD Symptom Checker", "url": "https://symptoms.webmd.com/", "description": "WebMD提供的症状检查工具，帮助用户了解可能的健康问题，提供自我检查和疾病诊断建议。" },
            { "name": "The National Institute of Mental Health (NIMH)", "url": "https://www.nimh.nih.gov/", "description": "提供关于心理健康、心理疾病的科学研究、临床试验和治疗信息，支持心理健康领域的研究与治疗。" }
          ]
          
          
          
    },

    {
        name: '工作犬',
        icon: 'Trophy',
        emoji: '🐕‍🦺',
        links:
        [
            { name: 'Doberman Blog', url: 'https://dobermanblog.com/', description: '杜宾博客，关于杜宾犬的博客' },
            { name: 'The Institute of Canine Biology', url: 'https://www.instituteofcaninebiology.org/', description: '犬类生物学研究所' },
            { name: 'Working Dog', url: 'https://en.working-dog.com/', description: '全球工作犬、视频、竞标赛' },
            { name: 'Bark and Whiskers', url: 'https://www.barkandwhiskers.com/', description: '全球犬类社区' }


          ]
          
          
          
    },

]


