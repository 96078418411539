<template>
  <div class="hot-news" :class="{ 'dark-theme': currentTheme === 'dark' }">
    <div class="hot-news-header">
      <div class="header-left">
        <img src="https://static.zhihu.com/heifetz/favicon.ico" alt="知乎" class="site-icon" @error="handleImageError" />
        <h3>知乎热榜</h3>
      </div>
      <div class="header-right">
        <span class="update-time" v-if="lastUpdateTime">
          更新于: {{ formatTime(lastUpdateTime) }}
        </span>
        <el-button type="primary" @click="refreshNews" :loading="loading" size="small" circle>
          <el-icon><Refresh /></el-icon>
        </el-button>
      </div>
    </div>
    <div class="hot-news-content">
      <el-scrollbar height="500px" v-if="newsList.length > 0">
        <transition-group name="news-list" tag="ul" class="news-list">
          <li v-for="(news, index) in newsList" 
              :key="news.guid || index" 
              class="news-item"
              @click="openNews(news.link)">
            <div class="news-info">
              <h4 class="news-title">
                {{ news.title }}
                <el-tag 
                  v-if="isNewArticle(news.pubDate)" 
                  size="small" 
                  class="news-tag"
                  effect="plain">
                  {{ formatNewsTag(news.pubDate) }}
                </el-tag>
              </h4>
              <p class="news-desc" v-if="news.description">{{ news.description }}</p>
              <div class="news-meta">
                <div class="news-info-left">
                  <el-tag 
                    size="small" 
                    :type="getTimeTagType(news.pubDate)" 
                    class="time-tag"
                    effect="light">
                    <el-icon class="time-icon"><Timer /></el-icon>
                    {{ formatDate(news.pubDate) }}
                  </el-tag>
                </div>
              </div>
            </div>
          </li>
        </transition-group>
      </el-scrollbar>
      <div v-else class="news-loading">
        <el-empty v-if="!loading && error" :description="error">
          <template #image>
            <el-icon size="40"><Warning /></el-icon>
          </template>
          <template #extra>
            <el-button type="primary" @click="refreshNews" size="small">
              重试
            </el-button>
          </template>
        </el-empty>
        <el-empty v-else-if="!loading" description="暂无热榜数据">
          <template #image>
            <el-icon size="40"><DocumentDelete /></el-icon>
          </template>
        </el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import { ElMessage } from 'element-plus'
import { Refresh, DocumentDelete, Warning, Timer } from '@element-plus/icons-vue'
import { API_ENDPOINTS } from '../config'

export default {
  name: 'InfoQNews',
  components: {
    Refresh,
    DocumentDelete,
    Warning,
    Timer
  },
  props: {
    currentTheme: {
      type: String,
      default: 'light'
    }
  },
  setup() {
    const newsList = ref([])
    const loading = ref(false)
    const error = ref('')
    const lastUpdateTime = ref(null)

    const loadNews = async () => {
      loading.value = true
      error.value = ''
      
      try {
        const response = await fetch(`${API_ENDPOINTS.RSS_PROXY}/zhihu/hot`)
        if (!response.ok) {
          throw new Error('获取热榜失败')
        }
        
        const text = await response.text()
        const parser = new DOMParser()
        const xmlDoc = parser.parseFromString(text, 'text/xml')
        
        const items = xmlDoc.querySelectorAll('item')
        newsList.value = Array.from(items).map(item => ({
          title: item.querySelector('title')?.textContent || '',
          description: item.querySelector('description')?.textContent?.replace(/<\/?p>/g, '') || '',
          link: item.querySelector('link')?.textContent || '',
          pubDate: item.querySelector('pubDate')?.textContent || '',
          guid: item.querySelector('guid')?.textContent || ''
        }))
        
        lastUpdateTime.value = new Date()
        error.value = ''
      } catch (err) {
        console.error('加载热榜失败:', err)
        error.value = err.message || '获取热榜数据失败，请稍后重试'
        newsList.value = []
      } finally {
        loading.value = false
      }
    }

    const refreshNews = () => {
      loadNews()
    }

    const openNews = (url) => {
      if (!url) {
        ElMessage.warning('暂无新闻链接')
        return
      }
      
      const finalUrl = url.startsWith('http') ? url : `https://${url}`
      window.open(finalUrl, '_blank', 'noopener,noreferrer')
    }

    const formatDate = (dateStr) => {
      if (!dateStr) return ''
      try {
        const date = new Date(dateStr)
        const now = new Date()
        const diff = now - date
        
        if (diff < 60 * 1000) { // 1分钟内
          return '刚刚'
        } else if (diff < 60 * 60 * 1000) { // 1小时内
          return `${Math.floor(diff / (60 * 1000))}分钟前`
        } else if (diff < 24 * 60 * 60 * 1000) { // 24小时内
          return `${Math.floor(diff / (60 * 60 * 1000))}小时前`
        } else {
          return date.toLocaleDateString('zh-CN', {
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          })
        }
      } catch {
        return dateStr
      }
    }

    const formatTime = (date) => {
      if (!date) return ''
      return date.toLocaleTimeString('zh-CN', {
        hour: '2-digit',
        minute: '2-digit'
      })
    }

    const getTimeTagType = (dateStr) => {
      if (!dateStr) return 'info';
      try {
        const date = new Date(dateStr);
        const now = new Date();
        const diff = now - date;
        const diffHours = diff / (1000 * 60 * 60);
        
        if (diffHours < 1) return 'danger';  // 1小时内
        if (diffHours < 6) return 'warning'; // 6小时内
        if (diffHours < 24) return 'success'; // 24小时内
        return 'info';
      } catch {
        return 'info';
      }
    };

    const formatNewsTag = (dateStr) => {
      if (!dateStr) return '';
      try {
        const date = new Date(dateStr);
        const now = new Date();
        const diff = now - date;
        const minutes = Math.floor(diff / (1000 * 60));
        return `${minutes}分钟前`;
      } catch {
        return '';
      }
    };

    const isNewArticle = (dateStr) => {
      if (!dateStr) return false;
      try {
        const date = new Date(dateStr);
        const now = new Date();
        const diff = now - date;
        return diff < 3600000; // 1小时内
      } catch {
        return false;
      }
    };

    const handleImageError = (e) => {
      e.target.src = 'https://static.zhihu.com/heifetz/assets/apple-touch-icon-152.a53ae37b.png'
    }

    let newsTimer = null

    onMounted(() => {
      loadNews()
      newsTimer = setInterval(loadNews, 5 * 60 * 1000) // 每5分钟刷新一次
    })

    onUnmounted(() => {
      if (newsTimer) {
        clearInterval(newsTimer)
      }
    })

    return {
      newsList,
      loading,
      error,
      lastUpdateTime,
      refreshNews,
      openNews,
      formatDate,
      formatTime,
      getTimeTagType,
      formatNewsTag,
      isNewArticle,
      handleImageError,
      Timer
    }
  }
}
</script>

<style scoped>
.hot-news {
  width: 100%;
  height: 560px;
  background: var(--el-bg-color);
  border-radius: 8px;
  box-shadow: var(--el-box-shadow-lighter);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.hot-news-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid var(--el-border-color-lighter);
}

.site-icon {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 4px;
  transition: filter 0.3s ease;
}

:deep(.dark) .hot-news,
.dark-theme {
  background: var(--el-bg-color-overlay);
  color: var(--el-text-color-primary);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
}

:deep(.dark) .site-icon,
.dark-theme .site-icon {
  filter: brightness(0.9);
}

:deep(.dark) .news-item,
.dark-theme .news-item {
  border-color: var(--el-border-color-darker);
}

:deep(.dark) .news-item:hover,
.dark-theme .news-item:hover {
  background-color: var(--el-fill-color-darker);
}

:deep(.dark) .news-title,
.dark-theme .news-title {
  color: var(--el-text-color-primary);
}

:deep(.dark) .news-desc,
:deep(.dark) .news-date,
:deep(.dark) .update-time,
.dark-theme .news-desc,
.dark-theme .news-date,
.dark-theme .update-time {
  color: var(--el-text-color-secondary);
}

.header-left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.header-left h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 8px;
}

.update-time {
  font-size: 12px;
  color: var(--el-text-color-secondary);
  transition: color 0.3s ease;
}

.hot-news-content {
  flex: 1;
  overflow: hidden;
}

.news-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.news-item {
  padding: 10px;
  border-bottom: 1px solid var(--el-border-color-lighter);
  cursor: pointer;
  transition: all 0.3s ease;
}

.news-item:hover {
  background-color: var(--el-fill-color-light);
}

.news-item:last-child {
  border-bottom: none;
}

.news-title {
  margin: 0 0 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  color: var(--el-text-color-primary);
  display: flex;
  align-items: center;
  gap: 8px;
  transition: color 0.3s ease;
}

.news-desc {
  margin: 6px 0;
  font-size: 12px;
  line-height: 1.5;
  color: var(--el-text-color-secondary);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: color 0.3s ease;
}

.news-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
}

.news-info-left {
  display: flex;
  align-items: center;
  gap: 6px;
}

.time-tag {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 0 8px;
  height: 22px;
  border-radius: 11px;
}

.time-icon {
  font-size: 12px;
}

.dark-theme .time-tag {
  border-color: var(--el-border-color-dark);
}

.news-date {
  font-size: 12px;
  color: var(--el-text-color-secondary);
  transition: color 0.3s ease;
}

.news-loading {
  padding: 20px;
  text-align: center;
}

.news-list-enter-active,
.news-list-leave-active {
  transition: all 0.3s ease;
}

.news-list-enter-from,
.news-list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.news-list-move {
  transition: transform 0.3s ease;
}
</style>
