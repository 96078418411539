export const aiTools = [
  
  { "name": "新闻网站","icon": "video-camera","emoji": "📰",
    "links": [
        {"name": "新浪新闻", "url": "https://news.sina.com.cn/", "description": "中国主流新闻网站之一，提供国内外时事新闻、财经、娱乐、体育等内容"},
        {"name": "BBC", "url": "https://www.bbc.com/", "description": "英国广播公司，提供全球新闻、分析、政治、文化等各类报道"},
        {"name": "CNN", "url": "https://www.cnn.com/", "description": "美国新闻网站，提供国际、国内新闻、财经、体育、健康等多方面报道"},
        {"name": "纽约时报", "url": "https://www.nytimes.com/", "description": "提供全球新闻、美国本土新闻、政治、经济、文化、科技等内容的综合性新闻平台"},
        {"name": "华尔街日报", "url": "https://www.wsj.com/", "description": "全球领先的商业、财经新闻网站，提供全球财经、股市、科技、政治等报道"},
        {"name": "路透社", "url": "https://www.reuters.com/", "description": "国际新闻机构，提供全球新闻、金融、经济、科技等实时更新"},
        {"name": "人民网", "url": "https://www.people.com.cn/", "description": "中国人民代表大会新闻平台，提供国家政策、时事新闻、民生热点等内容"},
        {"name": "环球时报", "url": "https://www.huanqiu.com/", "description": "中国的国际新闻平台，聚焦全球热点、国际事务、军事政治等内容"},
        {"name": "Al Jazeera", "url": "https://www.aljazeera.com/", "description": "中东新闻媒体，提供全球新闻、国际局势、文化报道等内容"},
        {"name": "中国日报", "url": "https://www.chinadaily.com.cn/", "description": "中国英语新闻网站，提供国内外新闻、文化、教育、科技等信息"},
        {"name": "腾讯新闻", "url": "https://news.qq.com/", "description": "腾讯新闻"},
        {"name": "网易新闻", "url": "https://www.163.com/", "description": "网易新闻"},
        
      

    ]
  },

  { "name": "视频网站","icon": "video-camera","emoji": "🎥",
    "links": [
      { "name": "YouTube","url": "https://www.youtube.com/","description": "YouTube"},
      {"name": "哔哩哔哩", "url": "https://www.bilibili.com/","description": "哔哩哔哩"},
      {"name": "爱奇艺", "url": "https://www.iqiyi.com/","description": "爱奇艺"},
      {"name": "腾讯视频", "url": "https://v.qq.com/","description": "腾讯视频"},
      {"name": "优酷", "url": "https://www.youku.com/","description": "优酷"},
      {"name": "土豆", "url": "https://www.tudou.com/","description": "土豆"},
      {"name": "芒果TV", "url": "https://www.mgtv.com/","description": "芒果TV"},
      {"name": "Twitch","url": "https://www.twitch.tv/","description": "Twitch直播"},
      {"name": "斗鱼","url": "https://www.douyu.com/","description": "斗鱼直播"},
      {name: '虎牙',url: 'https://www.huya.com/',description: '虎牙直播'},
      {"name": "TikTok","url": "https://www.tiktok.com/","description": "TikTok"},
      {"name": "抖音","url": "https://www.douyin.com/","description": "抖音"},
      {"name": "Netflix","url": "https://www.netflix.com/","description": "Netflix"},
      {"name": "快手","url": "https://www.kuaishou.com/","description": "快手"},
      {"name": "X视频下载","url": "https://twittervideodownloader.com/","description": "可以将Twitter视频下载到本地"},

    ]
  },
  { "name": "购物网站","icon": "shop","emoji": "🛒",
    "links": [
      { "name": "Amazon","url": "https://www.amazon.com/","description": "亚马逊"},
      {"name": "ebay","url": "https://www.ebay.com/","description": "ebay"},
      {"name": "Aliexpress","url": "https://www.aliexpress.com/","description": "Aliexpress"},
      {"name": "Shopee","url":"https://shopee.com/","description": "Shopee"},
      {"name": "淘宝","url": "https://www.taobao.com/","description": "Taobao"},
      {"name": "京东","url": "https://www.jd.com/","description": "JD"},
      {"name": "拼多多","url": "https://pinduoduo.com/","description": "拼多多"},
      {"name": "当当","url": "https://www.dangdang.com/","description": "当当"},

    ]
  },

  {
    "name": "社交通讯",
    "icon": "cpu",
    "emoji": "👥",
    "links": [
      {"name": "X","url": "https://x.com/","description": "前Twitter"},
      {"name": "Facebook","url": "https://www.facebook.com/", "description": "全球社交平台"},
      {"name": "Instagram","url": "https://www.instagram.com/","description": "图片和视频分享平台"},
      {"name": "小红书","url": "https://www.xiaohongshu.com/","description": "中国生活方式社交平台"},
      {"name": "LinkedIn","url": "https://www.linkedin.com/","description": "职场社交平台"},
      {"name": "Discord","url": "https://discord.com/","description": "在线语音、视频和文字聊天平台"},
      {"name": "Reddit","url": "https://www.reddit.com/","description": "社区新闻聚合网站"},
      {"name": "Telegram","url": "https://web.telegram.org/","description": "加密消息和文件传输"},
      {"name": "Tumblr","url": "https://www.tumblr.com/","description": "博客和社交平台"},
      {"name": "Vimeo","url": "https://vimeo.com/","description": "视频分享和流媒体平台"},
      {"name": "WeChat","url": "https://weixin.qq.com/","description": "中国多功能社交平台"},
      {"name": "知乎","url": "https://www.zhihu.com/","description": "中文问答社区"},
      {"name": "QQ","url": "https://im.qq.com/","description": "即时通讯平台"},
      {"name": "LINE","url": "https://www.line.me/","description": "通讯软件"},
      {"name": "TIM","url": "https://tim.qq.com/","description": "中国移动通讯平台"},
      {"name": "Whatsapp","url": "https://web.whatsapp.com/","description": "全球即时消息平台"},
      {"name": "Skype","url": "https://www.skype.com/","description": "Skype 是一个在线视频聊天和语音通话应用。"},
      {"name": "Beebeep","url": "https://www.beebeep.net/","description": "这是一款免费的办公通讯应用，14 年来一直致力于帮助像您这样的人保证消息的安全和私密"},
      {"name": "微博","url": "https://weibo.com/","description": "中国社交媒体平台"},
      {"name": "钉钉","url": "https://www.dingtalk.com/","description": "中国移动通讯平台"},
      {"name": "IT之家","url": "https://www.ithome.com/","description": "IT之家"},
      {"name": "ipmsg","url": "https://ipmsg.org/","description": "适用于 LAN 的无服务器轻量级 Messenger。"},
      {"name": "Mastodon","url": "https://joinmastodon.org/","description": "Mastodon 是一个开源的社交网络，基于自由软件和开放协议。"},
      {"name": "Mattermost","url": "https://mattermost.com/","description": "Mattermost 是一个开源的聊天和协作平台，支持多种语言和设备。"},
      {"name": "简书","url": "https://www.jianshu.com/", "description": "简书"},
      {"name": "百度贴吧","url": "https://tieba.baidu.com/index.html", "description": "百度贴吧"},
      { "name": "Quora", "url": "https://www.quora.com/", "description": "一个问答网站，用户可以提出问题、回答他人问题，涵盖广泛话题，包括科技、生活、文化等。" },
      { "name": "Hacker News", "url": "https://news.ycombinator.com/", "description": "一个由创业者、程序员和技术爱好者共同维护的新闻网站，关注科技、创业和编程等话题。" },
    ]
  },
  {
    "name": "AI 大模型",
    "icon": "cpu",
    "emoji": "🧠",
    "links": [
      {"name": "ChatGPT","url": "https://chatgpt.com/","description": "OpenAI开发的对话式AI模型，支持多种任务和语言"},
      {"name": "Google AI Studio","url": "https://aistudio.google.com/","description": "谷歌人工智能工作室"},
      {"name": "Claude","url": "https://claude.ai/","description": "Anthropic开发的AI助手，强调安全和协作"},
      {"name": "Gemini","url": "https://gemini.google.com/app","description": "Google推出的多模态AI助手，整合搜索和生成能力"},
      {"name": "ollama","url": "https://ollama.com/","description": "开源大模型"},
      {"name": "mistral","url": "https://mistral.ai/","description": "一家专注于开发先进语言模型和人工智能解决方案的前沿AI公司。"},
      {"name": "Grok","url": "https://x.ai","description": "一家专注于开发先进语言模型和人工智能解决方案的前沿AI公司。"},
      {"name": "Cohere","url": "https://coral.cohere.com/","description": "一家专注于开发先进语言模型和人工智能解决方案的前沿AI公司。"},
      {"name": "PentestGPT","url": "https://pentestgpt.ai/","description": "渗透测试专用AI模型。"},
      {"name": "OSMO","url": "https://www.osmo.ai/","description": "利用AI让计算机识别味觉"},
      {"name": "deepmind","url": " https://deepmind.google/","description": "Google的深度学习公司。"},
      {"name": "Jasper","url": "https://www.jasper.ai/","description": "AI写作助手"},
      {"name": "Notion AI","url": "https://www.notion.so/product/ai","description": "Notion的AI助手"},
      {"name": "NotebookLM","url": "https://notebooklm.google.com/","description": "Google AI笔记"},
      {"name": "通义法睿","url": "https://tongyi.aliyun.com/farui?utm_source=ai-bot.cn","description": "法律助手"},
      {"name": "Kimi","url": "https://kimi.moonshot.cn/","description": "提供多种功能整合的AI助手聚合平台"},
      {"name": "豆包","url": "https://doubao.com/","description": "聚合多种AI助手和服务的本地化平台"},
      {"name": "DeepSeek","url": "https://coder.deepseek.com/","description": "AI大模型"},
      {"name": "天宫AI","url": "https://www.tiangong.cn/","description": "。"},
      { "name": "零一万物","url": "https://www.lingyiwanwu.com/","description": "AI模型"},
      { "name": "alphafold", "url": "https://deepmind.google/technologies/alphafold/", "description": "利用人工智能加速生物学突破。" },
      { "name": "Insilico Medicine", "url": "https://insilico.com/", "description": "生物药物研发相关模型" },
      { "name": "Butterfly Network AI", "url": "https://www.butterflynetwork.com/", "description": "超声影像诊断" },
      { "name": "RadImageNet", "url": "https://www.radimagenet.com/", "description": "针对医学影像的深度学习模型" },
      {"name": "Perplexity AI", "url": "https://perplexity.ai/","description": "Perplexity AI 是一个 AI 驱动的搜索引擎，可以生成和理解文本。"},
      {"name": "You","url": "https://you.com/","description": "You 是一个 AI 驱动的搜索引擎，可以生成和理解文本。"},
      {"name": "topai","url": "https://topai.tools/", "description": "每项任务的最佳 AI 工具"},
      {"name": "RunwayML","url": "https://runwayml.com/", "description": "提供多种 AI 模型和工具，帮助用户快速构建和部署 AI 应用程序。"},
      {"name": "Poe","url": "https://poe.com/","description": "一个聚合不同AI助手的平台,支持快速切换与对比"},
      {"name": "Hugging Space","url": "https://huggingface.co/spaces","description": "展示和试用各种AI应用的在线平台"},
      {"name": "LobeChat","url": "https://chat-preview.lobehub.com/chat","description": "一个开源、现代设计的 AI 聊天框架。"},
      {"name": "ChatGPT Proxy","url": "https://vercel.ddaiai.com/","description": "一个 UI 全部通过 chatgpt web、midjourney、gpts、suno、luma、runway、viggle、flux、ideogram、realtime、pika、udio 完成；同时支持 Web / PWA / Linux / Win / MacOS 平台"},
      {"name": "ChatNio","url": "https://www.chatnio.com/","description": "AI聊天平台"},
      {"name": "魔塔社区","url": "https://www.modelscope.cn/home","description": "魔塔社区"},
      {"name": "Geospy","url": "https://geospy.ai/","description": "地图SPY，通过图片定位位置。"},
      {
        "name": "LM Studio",
        "url": "https://lmstudio.ai/",
        "description": "在你的电脑上运行Llama， Mistral, Phi-3。"
      },
      {
        "name": "jan",
        "url": "https://jan.ai/",
        "description": "Jan 是一个开源的 ChatGPT 替代品，可 100% 离线运行。"
      },
      {
        "name": "rubbrband",
        "url": "https://www.rubbrband.com/home",
        "description": "一站式媒体生成"
      },
      {
        "name": "deepai",
        "url": "https://deepai.org/",
        "description": "一站式媒体生成"
      },
      {
        "name": "flowgpt",
        "url": "https://flowgpt.com/",
        "description": "各种角色对话"
      },
      {"name": "paperswithcode","url": "  https://paperswithcode.com/sota/multi-task-language-understanding-on-mmlu","description": "模型评测"}
    
    ]
  },

  {
    "name": "AI 文生图",
    "icon": "Picture",
    "emoji": "🎨",
    "links": [
      {"name": "imagen-3", "url": "https://deepmind.google/technologies/imagen-3/", "description": "Google出品"},
      {"name": "Midjourney", "url": "https://www.midjourney.com/", "description": "高质量AI图像生成工具，支持多种风格"},
      {"name": "Stable Diffusion", "url": "https://stability.ai/", "description": "开源AI图像生成模型，适合开发者和创作者"},
      {"name": "FLUX", "url": "https://blackforestlabs.ai/", "description": "基于浏览器的在线AI绘画工具，支持创意艺术设计"},
      {"name": "Leonardo.ai", "url": "https://leonardo.ai/", "description": "为创意专业人士设计的AI生成艺术和设计平台"},
      {"name": "Firefly", "url": "https://firefly.adobe.com/", "description": "Adobe推出的AI工具，支持文本生成图像等多种功能"},
      {"name": "ComfyUI", "url": "https://www.comfy.org/", "description": "AI绘画平台，支持多种风格和场景"},
      {"name": "Playground AI", "url": "https://playground.ai/", "description": "免费在线AI绘画工具，适合初学者和爱好者"},
      {"name": "Civitai", "url": "https://civitai.com/", "description": "支持AI模型分享和图像生成的社区平台"},
      {"name": "Canva", "url": "https://www.canva.com/", "description": "Canva平台内置的AI图像生成功能，方便设计创作"},
      {"name": "Prompter", "url": "https://prompter.ai/", "description": "AI绘图提示词生成平台，提供多种风格和场景"},
      {"name": "Promptify", "url": "https://promptify.ai/", "description": "AI绘图提示词生成平台，提供多种风格和场景"},
      {"name": "Prompthero", "url": "https://prompthero.com/", "description": "AI艺术创作的提示词社区，提供丰富的灵感和资源"},
      {"name": "Prompthunt", "url": "https://www.prompthunt.com/", "description": "AI绘画平台，展示和分享不同提示词生成的作品"}
    ]
  },

  {
    "name": "AI 抠图",
    "icon": "Picture",
    "emoji": "✂️",
    "links": [
      {"name": "BRIA-RMBG-2.0","url": "https://huggingface.co/spaces/briaai/BRIA-RMBG-2.0","description": "AI工具，用于快速抠图和背景移除"},
      {"name": "photoroom","url": "https://www.photoroom.com/zh/tools/background-remover","description": "提供高精度背景移除和图像编辑功能"},
      {"name": "cleanupphotos","url": "https://cleanupphotos.com/","description": "AI驱动的抠图工具，支持快速清理照片背景"}
    ]
  },
  {
    "name": "AI 删除物体","icon": "Picture","emoji": "🚮",
    "links": [
      {"name": "Rembg","url": "https://rembg.com/","description": "AI驱动的图像去物体工具，支持快速清理照片背景"},
      {"name": "Remove.bg","url": "https://www.remove.bg/","description": "免费的AI驱动的图像去物体工具，支持快速清理照片背景"},
      {"name": "Kaze","url": "https://kaze.ai/","description": "AI驱动的去除水印"}
    ]
  },
  {
    "name": "AI 文生视频",
    "icon": "video-camera",
    "emoji": "🎬",
    "links": [
      { "name": "Sora", "url": " https://sora.com/", "description": "Open ai sora" },
      { "name": "Veo", "url": "https://deepmind.google/technologies/veo/veo-2/", "description": "Google AI 文生视频"},
      { "name": "Vidu", "url": " https://www.vidu.studio", "description": "清华大学，文生视频" },
      { "name": "Synthesia", "url": "https://www.synthesia.io", "description": "创建基于AI生成的人物视频，支持文本转视频" },
      { "name": "Runway ML", "url": "https://runwayml.com", "description": "视频生成、特效制作，支持文本提示生成视频" },
      { "name": "Pictory", "url": "https://pictory.ai", "description": "自动将文字转化为短视频，适用于内容创作者" },
      { "name": "Fliki", "url": "https://fliki.ai", "description": "将文字转化为带AI语音的视频，支持多语言" },
      { "name": "Animaker", "url": "https://www.animaker.com", "description": "AI辅助制作动画视频，适合简报、宣传内容" },
      { "name": "Kaiber", "url": "https://kaiber.ai", "description": "通过AI生成动画短片，支持创意视觉表达" },
      { "name": "Vyond", "url": "https://www.vyond.com", "description": "AI辅助动画制作，适合教学或企业内容" },
      { "name": "海螺AI", "url": "https://hailuoai.com/", "description": "海螺AI" },
      { "name": "腾讯混元文生视频", "url": "https://video.hunyuan.tencent.com/", "description": "腾讯混元大模型是由腾讯研发的大语言模型，具备跨领域知识和自然语言理解能力，实现基于人机自然语言对话的方式，理解用户指令并执行任务，帮助用户实现人获取信息，知识和灵感。" }
      
    ]

  },
  {
    "name": "AI 视频处理",
    "icon": "video-camera",
    "emoji": "📹",
    "links": [
      { "name": "Descript", "url": "https://www.descript.com", "description": "视频编辑、自动字幕生成、语音替换等" },
      { "name": "CapCut", "url": "https://www.capcut.com", "description": "在线视频编辑，支持AI特效和字幕生成" },
      { "name": "VEED.IO", "url": "https://www.veed.io", "description": "在线视频编辑，提供AI字幕和翻译功能" },
      { "name": "Topaz Video AI", "url": "https://www.topazlabs.com/video-ai", "description": "视频去噪、分辨率提升、帧率增强" },
      { "name": "Pixop", "url": "https://pixop.com", "description": "AI视频修复与增强，适用于老旧视频优化" },
      { "name": "Sonix", "url": "https://sonix.ai", "description": "视频转录、字幕生成和翻译" },
      { "name": "Happy Scribe", "url": "https://www.happyscribe.com", "description": "AI字幕生成，支持多语言转录和翻译" },
    ]
  },
  {
    "name": "AI 数字人",
    "icon": "User",
    "emoji": "👥",
    "links": [
      { "name": "DeepBrain AI", "url": "https://deepbrain.io", "description": "AI数字人视频生成，适合企业内容制作" },
      { "name": "arcads", "url": "https://www.arcads.ai/", "description": "数字人" },
      { "name": "rendernet", "url": "https://rendernet.ai/", "description": "虚拟网红" },
      { "name": "通义星辰", "url": "https://tongyi.aliyun.com/xingchen", "description": "角色对话智能体" }
    ]
  },
  {
    "name": "AI 换脸",
    "icon": "User",
    "emoji": "🤣",
    "links": [
      { "name": "facefusion", "url": "https://github.com/facefusion/facefusion","description": "开源换脸工具，支持高质量脸部融合"},
      { name: 'FaceSwap', url: 'https://faceswap.dev', description: '开源换脸工具，支持自定义数据集进行训练，适用于视频和图像换脸。' },
      { name: 'Zao', url: 'https://www.zaoapp.com', description: '中国开发的换脸应用，通过几张用户照片生成逼真的换脸效果。' },
      { name: 'Reface', url: 'https://www.reface.app', description: '面向普通消费者的换脸应用，能够将面部替换到电影、GIF等内容中，生成快速且逼真的换脸效果。' },
      { name: 'DeepFaceLab', url: 'https://github.com/iperov/DeepFaceLab', description: '强大的深度学习换脸工具，专为视频和图像换脸设计，支持高质量合成。' },
      { name: 'FaceApp', url: 'https://www.faceapp.com', description: '提供面部编辑功能，包括换脸、年龄变化、性别转换等，操作简单，广受欢迎。' },
      { name: 'Avatarify', url: 'https://github.com/alievk/avatarify', description: '实时换脸工具，支持在视频通话和直播中实时换脸，适用于个人和企业。' },
      { name: 'Face2Face', url: 'http://www.face2face.cs.princeton.edu', description: '基于面部捕捉和实时视频处理的换脸技术，适用于电影和虚拟现实的实时互动换脸。' },
  
    ]
  },
  {
    "name": "AI 换衣",
    "icon": "User",
    "emoji": "👗",
    "links": [
      {
        "name": "TryonAI",
        "url": "https://tryon.ai/",
        "description": "AI换衣服"
      }
    ]
  },
  {
    "name": "AI 语音克隆",
    "icon": "Headset",
    "emoji": "🎧",
    "links": [
      {
        "name": "Deepgram",
        "url": "https://deepgram.com/",
        "description": "Deepgram 提供基于AI的语音识别平台，能够提供准确的转录和音频搜索功能，适用于大规模数据处理。"
      },
      {
        "name": "Whisper",
        "url": "https://openai.com/blog/whisper/",
        "description": "Whisper 是 OpenAI 开发的多语言语音识别系统，支持语音转文本、翻译和语言识别等功能。"
      },
      {
        "name": "Fish",
        "url": "https://fish.audio/zh-CN/",
        "description": "Fish 提供实时语音识别服务，适用于音频和视频内容的转录。"
      },
      {
        "name": "F5-TTS",
        "url": "https://f5tts.org/",
        "description": "Deepgram语音识别平台"
      }
    ]
  },
  {
    "name": "翻译工具",
    "icon": "ChatDotRound",
    "emoji": "🌎",
    "links": [
      { "name": "DeepL","url": "https://www.deepl.com/zh/translator","description": "DeepL 提供高质量的AI翻译，尤其在欧洲语言的翻译精度上表现突出。"},
      {"name": "Google Translate","url": "https://translate.google.com/","description": "Google 翻译支持多语言互译，具备强大的翻译引擎和语音输入功能，广泛用于日常交流。"},
      {"name": "Bing Translator","url": "https://www.bing.com/translator","description": "Bing 翻译是微软推出的翻译工具，支持多语言互译，并可提供自动语音翻译功能。"},
      {"name": "百度翻译","url": "https://fanyi.baidu.com/","description": "百度翻译是一款多语言翻译工具，支持实时翻译和历史记录。"},
      {"name": "腾讯翻译","url": "https://fanyi.qq.com/","description": "腾讯翻译是腾讯提供的翻译工具，支持多语言互译，并可以提供翻译历史记录。"},
      {"name": "有道翻译","url": "https://fanyi.youdao.com/","description": "有道翻译提供多语言互译服务，支持快速翻译和翻译历史查询。"},
      {"name": "沉浸式翻译","url": "https://immersivetranslate.com/","description": "你完全免费地使用它来实时翻译外语网页，PDF翻译，EPUB电子书翻译，视频双语字幕翻译等"},
      {"name": "金山词霸","url": "https://www.iciba.com/","description": "金山词霸"},
      {"name": "PyVideoTrans","url": "https://pyvideotrans.com/","description": "PyVideoTrans 是一个免费的在线视频翻译工具，支持多种语言的视频翻译和字幕翻译。"},
      {"name": "PyVideoTrans","url": "https://github.com/jianchang512/pyvideotrans","description": "PyVideoTrans 是一个免费的在线视频翻译工具，支持多种语言的视频翻译和字幕翻译。"},
      {"name": "HeyGen","url": "https://www.heygen.com/","description": "视频翻译工具，支持多种语言的视频翻译和字幕翻译。"},
      {"name": "Rask","url": "https://www.rask.ai/","description": "视频翻译工具，支持多种语言的视频翻译和字幕翻译。"},

    ]
  },
  {
    "name": "AI 编程",
    "icon": "Edit",
    "emoji": "💻",
    "links": [
      {"name": "Copilot","url": "https://github.com/features/copilot","description": "GitHub AI"},
      {"name": "Cursor","url": "https://cursor.so/","description": "AI"},
      {"name": "Windsurf","url": "https://codeium.com/windsurf","description": "AI"},
      {"name": "Bolt","url": "https://bolt.new/","description": "AI"},
      {"name": "v0","url": "https://v0.dev/","description": "AI"},
      {"name": "Devin","url": "https://app.devin.ai/","description": "AI"},
      { "name": "豆包MarsCode","url": "https://www.marscode.cn/","description": "AI代码"},
      {"name": "文心快码","url": "https://comate.baidu.com/zh","description": "基于文心大模型，结合百度编程大数据，为你生成优质编程代码。"},
      {"name": "通义灵码","url": "https://tongyi.aliyun.com/lingma","description": "基于通义大模型，提供代码智能生成、研发智能问答能力"},
      {"name": "Tabby","url": "https://www.tabbyml.com/", "description": "Tabby 是一款开源、自托管的 AI 编码助手。借助 Tabby，每个团队都可以轻松设置自己的 LLM 驱动的代码完成服务器。"}
    ]
  },
  {
    "name": "AI 音乐",
    "icon": "Headset",
    "emoji": "🎵",
    "links": [
      { "name": "Suno","url": "https://www.suno.ai/","description": "AI音乐生成"},
      {"name": "Udio","url": "https://www.udio.com/","description": "AI音乐生成"},
      {"name": "AIVA","url": "https://www.aiva.ai/","description": "AI音乐生成"},
    ]
  },
  {
    "name": "视觉识别",
    "icon": "Picture",
    "emoji": "🔍",
    "links": [
      {"name": "yolo","url": "https://www.ultralytics.com/","description": "yolo视觉识别"},
      { name: 'Faster R-CNN', url: 'https://github.com/ShaoqingRen/faster_rcnn', description: '结合区域提议网络和卷积神经网络的目标检测模型' },
      { name: 'VGGNet', url: 'https://arxiv.org/abs/1409.1556', description: '结构简单的卷积神经网络，广泛应用于图像分类任务' },
      { name: 'Inception', url: 'https://arxiv.org/abs/1409.4842', description: '通过多个卷积核的组合来提取图像特征，提高了模型的效率和精度' },
      { name: 'Mask R-CNN', url: 'https://github.com/matterport/Mask_RCNN', description: '用于目标检测和语义分割的模型' },
      { name: 'U-Net', url: 'https://arxiv.org/abs/1505.04597', description: '适用于医学影像分割的对称编码器-解码器结构' },
      { name: 'DeepLab', url: 'https://github.com/tensorflow/models/tree/master/research/deeplab', description: '高效的语义分割模型，特别适用于场景分割' },
      { name: 'FaceNet', url: 'https://github.com/davidsandberg/facenet', description: '强大的人脸识别框架，通过特征向量来进行人脸匹配' },
      { name: 'OpenCV', url: 'https://opencv.org', description: '开源计算机视觉库，提供人脸检测和识别的工具' },
      { name: 'OpenPose', url: 'https://github.com/CMU-Perceptual-Computing-Lab/openpose', description: '用于人体姿态估计的开源工具' },
      { name: 'AlphaPose', url: 'https://github.com/MVIG-SJTU/AlphaPose', description: '高效的多人姿态估计方法，能够实时处理复杂的姿态识别任务' },
      { name: 'GANs (Generative Adversarial Networks)', url: 'https://arxiv.org/abs/1406.2661', description: '通过生成器和判别器的对抗训练生成高质量图像' },
      { name: 'VQ-VAE', url: 'https://arxiv.org/abs/1711.00937', description: '基于变分自编码器的生成模型，能够生成高分辨率图像' },
      { name: 'Tesseract', url: 'https://github.com/tesseract-ocr/tesseract', description: '开源OCR引擎，支持多种语言和字符识别' },
      { name: 'SIFT (Scale-Invariant Feature Transform)', url: 'https://en.wikipedia.org/wiki/Scale-invariant_feature_transform', description: '用于图像特征提取和匹配的算法' },
      { name: 'Deep Visual Semantic Embedding', url: 'https://arxiv.org/abs/1608.02574', description: '基于深度学习的图像检索方法，将图像映射到语义空间进行检索' },
      { name: 'C3D', url: 'https://github.com/facebookarchive/C3D', description: '用于视频动作识别的3D卷积神经网络' },
      { name: 'I3D', url: 'https://arxiv.org/abs/1705.07750', description: '基于Inception-V1架构的3D卷积神经网络，应用于动作识别' },
      
    ]
  },
  {
    "name": "文字识别",
    "icon": "Picture",
    "emoji": "✍️",
    "links": [
      { name: 'EasyOCR', url: 'https://github.com/JaidedAI/EasyOCR', description: '基于深度学习的OCR库，支持多种语言和文本识别' },
      { name: 'Tesseract', url: 'https://github.com/tesseract-ocr/tesseract', description: '开源的OCR引擎，支持多种语言和字符识别，广泛应用于文档扫描、文本提取等场景' },
      { name: 'Google Cloud Vision OCR', url: 'https://cloud.google.com/vision', description: 'Google云平台提供的OCR服务，能够识别图像中的文本，支持多种语言和复杂格式' },
      { name: 'Microsoft Azure OCR', url: 'https://azure.microsoft.com/en-us/services/cognitive-services/computer-vision/', description: '微软Azure认知服务提供的OCR功能，支持图像和PDF文件中的文字提取' },
      { name: 'OCR.space', url: 'https://ocr.space/', description: '提供免费的OCR API，支持多种语言，适用于快速的文字提取和扫描文档分析' },
      { name: 'ABBYY FineReader', url: 'https://www.abbyy.com/finereader/', description: '一款商用OCR软件，支持高级文档编辑、文本识别和PDF转换' },
      { name: 'PaddleOCR', url: 'https://github.com/PaddlePaddle/PaddleOCR', description: '百度开发的开源OCR工具，支持多种语言和场景，特别适合中文文字识别' },
      { name: 'TextRecognitionDataGenerator', url: 'https://github.com/Belval/TextRecognitionDataGenerator', description: '一个生成自定义文本数据集的工具，适用于训练OCR模型' },
      { name: 'Keras-OCR', url: 'https://github.com/faizann24/keras-ocr', description: '基于Keras的OCR实现，适合深度学习爱好者进行OCR研究和应用' },
      { name: 'Pytesseract', url: 'https://github.com/madmaze/pytesseract', description: 'Tesseract的Python封装，简化了与Tesseract OCR引擎的集成' },
      { name: 'VietOCR', url: 'https://github.com/vietocr/vi-et-ocr', description: '一个支持越南语及其他语言的OCR工具，适合跨语言应用' },
      { name: 'CuneiForm', url: 'https://sourceforge.net/projects/cuneiform/', description: '开源OCR工具，支持多种语言，尤其适用于历史文献和古文字的识别' },
      { name: 'Docsumo', url: 'https://www.docsumo.com/', description: '文档分析和OCR工具，支持提取表单、发票、收据等结构化数据' },
      { name: 'OCRopus', url: 'https://github.com/tmbdev/ocropy', description: '开源OCR系统，能够进行文档布局分析和文本识别，适用于学术研究和开发' }
    ]
  },
 
];