export const downloads = [
  {
    "name": "软件下载",
    "icon": "download",
    "emoji": "💾",
    "links": [
      {"name": "OSSSR","url": "https://www.osssr.com/","description": "优质软件资源下载站"},
      {"name": "果核剥壳","url": "https://www.ghxi.com/","description": "绿色软件下载站"},
      {"name": "腾讯软件中心","url": "https://pc.qq.com/","description": "软件下载平台"},
      {"name": "小众软件","url": "https://www.appinn.com/","description": "分享免费实用软件"},
      {"name": "Office Tool Plus","url": "https://otp.landian.vip/","description": "Office部署工具"},
      {"name": "大眼仔旭","url": "https://www.dayanzai.me/","description": "软件下载"},
      {"name": "sourceforge","url": "https://sourceforge.net/","description": "软件下载"},
      {"name": "异次元软件世界","url": "https://www.iplaysoft.com/","description": "软件下载"},
      {"name": "360软件管家","url": "https://baoku.360.cn/","description": "360软件管家"},
      {"name": "osdn","url": "https://osdn.net/","description": "开源软件网络"},
      {"name": "ZOL","url": "https://xiazai.zol.com.cn/","description": "软件下载"},
      {"name": "th-sjy","url": "http://www.th-sjy.com/","description": "软件汉化"},
      {"name": "crystalmark","url": "https://crystalmark.info/","description": "硬盘硬件测试"},
      {"name": "Balabolka","url": "https://www.cross-plus-a.com/cn/balabolka.htm","description": "Balabolka是一个文本转语音（TTS）的程序。Balabolka可以使用计算机系统上安装的所有语音。屏幕上的文字可以被保存为一个WAV，MP3，OGG或者WMA文件。"},
      {"name": "423down","url": "https://www.423down.com/","description": "软件下载"},
    ]
  },

  {
    "name": "输入法",
    "icon": "download",
    "emoji": "🔢",
    "links": [
      {"name": "搜狗输入法","url": "https://shurufa.sogou.com/","description": "搜狗输入法"},
      {"name": "微信输入法","url": "https://z.weixin.qq.com/","description": "微信输入法"},
    ]
  },



  {
    "name": "影视观看",
    "icon": "video-play",
    "emoji": "🎬",
    "links": [
      {"name": "人人影视","url": "https://www.renren.pro/","description": "影视库"},
      {"name": "影视库","url": "https://www.lovejay.top/%E5%BD%B1%E8%A7%86%E5%BA%93","description": "影视库"},
      {"name": "emby","url": "https://emby.media/","description": "媒体服务器的个人流媒体电影电视音乐照片在移动应用程序或浏览器的所有设备"},
      {"name": "银河录像局","url": "https://nf.video/","description": "账号租借平台"},
      {"name": "vlc","url": "https://www.videolan.org/vlc/","description": "免费、开源、跨平台的媒体播放器"},
      {"name": "obs","url": "https://obsproject.com/","description": "开源、跨平台的视频录制工具"},
      {"name": "pluto","url": "https://pluto.tv/","description": "在线观看免费电影、电视剧"},
      {"name": "tubi","url": "https://gdpr.tubi.tv/","description": "在线观看免费电影、电视剧"},
    ]
  },
  {
    "name": "系统下载",
    "icon": "cpu",
    "emoji": "💻",
    "links": [
      { "name": "msdn我告诉你","url": "https://msdn.itellyou.cn/","description": "msdn"},
      {"name": "Linux","url": "https://www.Linux.org","description": "Linux"},
      {"name": "Android","url": "https://www.Android.org","description": "Android"},
      {"name": "iOS","url": "https://www.iOS.org","description": "iOS"},
      {"name": "Ubuntu","url": "https://ubuntu.com/","description": "Ubuntu"},
      {"name": "CentOS","url": "https://www.CentOS.org","description": "CentOS"},
      {"name": "Debian","url": "https://www.Debian.org","description": "Debian"},
      {"name": "Kali Linux","url": "https://www.kali.org/","description": "Kali Linux"},
      {"name": "openSUSE","url": "https://www.openSUSE.org","description": "openSUSE"},
      {"name": "Slackware","url": "https://www.Slackware.com","description": "Slackware"},
      {"name": "Red Hat Linux","url": "https://www.redhat.com/","description": "redhat"},
      {"name": "Fedora","url": "https://www.Fedora.org","description": "Fedora"},
      {"name": "Gentoo","url": "https://www.Gentoo.org","description": "Gentoo"},
      {"name": "Arch Linux","url": "https://www.ArchLinux.org","description": "Arch Linux"},
      {"name": "Mageia","url": "https://www.Mageia.org","description": "Mageia"},
      {"name": "微PE工具箱","url": "https://www.wepe.com.cn/","description": "微PE工具箱"},
      {"name": "ParrotSec","url": "https://www.parrotsec.org/","description": "Parrot Security 提供大量工具、实用程序和库，IT 和安全专业人员可以使用它们以可靠、合规和可重复的方式测试和评估其资产的安全性。"},
      {"name": "ParrotSec","url": "https://blackarch.org/","description": "BlackArch Linux 是一款基于Arch Linux 的渗透测试发行版"},
      {"name": "HirensBootCD","url": "https://www.hirensbootcd.org/","description": "它包括一组精心挑选的最佳免费工具，专为支持 UEFI 启动且需要至少 4 GB RAM 的现代计算机而设计。"},
      {"name": "系统玩家","url": "https://www.xitongwanjia.com/","description": "Windows系统下载"},
    ]
  }
];