export const API_BASE_URL = 'http://192.168.31.81:3001'

export const API_ENDPOINTS = {
  RSS_PROXY: `${API_BASE_URL}/api/proxy/rsshub`,
  BING_SUGGEST: 'http://api.bing.com/qsonhs.aspx'
}

export const SEARCH_ENGINES = [
  {
    name: 'Google',
    icon: 'https://www.google.com/favicon.ico',
    searchUrl: 'https://www.google.com/search?q=',
    suggestUrl: 'https://suggestqueries.google.com/complete/search?client=firefox&q='
  },
  {
    name: 'Bing',
    icon: 'https://www.bing.com/favicon.ico',
    searchUrl: 'https://www.bing.com/search?q=',
    suggestUrl: 'http://api.bing.com/qsonhs.aspx?type=cb&q='
  },
  {
    name: 'Baidu',
    icon: 'https://www.baidu.com/favicon.ico',
    searchUrl: 'https://www.baidu.com/s?wd=',
    suggestUrl: 'https://suggestion.baidu.com/su?wd='
  },
  {
    name: 'GitHub',
    icon: 'https://github.com/favicon.ico',
    searchUrl: 'https://github.com/search?q=',
    suggestUrl: null
  }
]

export default {
  API_BASE_URL,
  API_ENDPOINTS,
  SEARCH_ENGINES
}
